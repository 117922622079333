<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div
              class="text-sm text-center copyright text-muted text-lg-start"
          >
            ©{{ new Date().getFullYear() }}, made with
            <i class="fa fa-heart"></i> by
            <a
                href="https://www.filcronet.it"
                class="font-weight-bold"
                target="_blank"
            >Filcronet</a>

          </div>
        </div>
<!--        <div class="col-lg-6">-->
<!--          <ul-->
<!--              class="nav nav-footer justify-content-center justify-content-lg-end"-->
<!--          >-->
<!--            <li class="nav-item">-->
<!--              <a-->
<!--                  href="https://www.filcronet.it"-->
<!--                  class="nav-link text-muted"-->
<!--                  target="_blank"-->
<!--              >Filcronet</a>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <a-->
<!--                  href="https://www.creative-tim.com/presentation"-->
<!--                  class="nav-link text-muted"-->
<!--                  target="_blank"-->
<!--              >About Us</a-->
<!--              >-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <a-->
<!--                  href="https://www.creative-tim.com/blog"-->
<!--                  class="nav-link text-muted"-->
<!--                  target="_blank"-->
<!--              >Blog</a-->
<!--              >-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <a-->
<!--                  href="https://www.creative-tim.com/license"-->
<!--                  class="nav-link pe-0 text-muted"-->
<!--                  target="_blank"-->
<!--              >License</a-->
<!--              >-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
};
</script>
