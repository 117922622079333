import { defineStore } from "pinia";

export default defineStore("search_components", {
  state: () => ({
    search_data: {},
  }),
  actions: {
    async setSearchData(searchData: any) {
      this.search_data = searchData;
    },
    async getSearchData() {
      return this.search_data;
    }
  },
});
