<template>
  <div class="container-fluid"></div>
</template>

<script>
import axios from "axios";
import router from "@/router";

export default {
  name: "tube-image",
  data() {
    return {
      idTube: "",
    };
  },
  beforeMount() {
    this.idTube = this.$route.params.id;
  },
  async mounted() {
    await this.getImage();
  },
  methods: {
    async getImage() {
      try {
        if (this.idTube) {
          const response = await axios.get(
            this.$endpoint + "/commessa/tube/image/" + this.idTube,
            {
              responseType: "blob",
              headers: {
                authorization: process.env.VUE_APP_APY_KEY,
              },
            }
          );

          // Converti l'Array Buffer in un Blob
          const excelBlob = new Blob([response.data], {
            type: response.headers.get("content-type"),
          });


          // Crea un URL oggetto dal Blob
          const excelURL = URL.createObjectURL(excelBlob);
          // Crea un elemento <a> e impostalo per il download
          const link = document.createElement("a");
          link.href = excelURL;
          let fileName = `immagine-tubo.jpg`;
          if (response.headers.get("content-type") === "image/png") {
            fileName = `immagine-tubo.png`;
          }

          link.setAttribute("download", fileName);

          // Aggiungi l'elemento <a> al DOM
          document.body.appendChild(link);

          // Clicca sul link per avviare il download
          link.click();

          // Rimuovi l'elemento <a> dal DOM
          document.body.removeChild(link);
          await router.push("/");
        }
      } catch (e) {
        console.log(e.toString());
      }
    },
  },
};
</script>
