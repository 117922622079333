<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Login</h4>
                  <p class="mb-0">
                    Inserisci la tua email e password per accedere
                  </p>
                </div>

                <div class="card-body">
                  <argon-alert color="danger" v-if="login_show_alert"
                    >{{ login_alert_msg }}
                  </argon-alert>
                  <Form>
                    <div class="mb-3">
                      <vee-field
                        type="email"
                        class="form-control"
                        name="email"
                        id="email"
                        v-model="this.email"
                        placeholder="E-mail"
                      />
                      <ErrorMessage class="text-danger" name="email" />
                    </div>
                    <div class="mb-3">
                      <vee-field
                        type="password"
                        class="form-control"
                        name="password"
                        id="password"
                        v-model="this.password"
                        placeholder="Password"
                      />
                      <ErrorMessage class="text-danger" name="password" />
                    </div>
                    <!--                    <argon-switch id="rememberMe">Ricordati di me</argon-switch>-->

                    <div class="text-center">
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                        @click.prevent="login"
                        >Accedi
                      </argon-button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg');
                  background-size: cover;
                "
              >
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >
                  "Attention is the new currency"
                </h4>
                <p class="text-white position-relative">
                  The more effortless the writing looks, the more effort the
                  writer actually put into the process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions } from "pinia";
import axios from "axios";
import router from "@/router";
import { useUserStore } from "@/store/user";
import ArgonAlert from "@/components/ArgonAlert.vue";
import { ErrorMessage } from "vee-validate";
import ArgonButton from "@/components/ArgonButton.vue";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "app-login",
  components: {
    ArgonButton,
    ErrorMessage,
    ArgonAlert,
  },
  mounted() {
    const storeUser = useUserStore();
    storeUser.autoRegister();

    if (storeUser.userLoggedIn) {
      router.push("/");
    } else {
      router.push("/login");
    }
  },
  data() {
    return {
      login_show_alert: false,
      login_alert_msg: "",
      email: "",
      password: "",
    };
  },
  created() {
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapActions(useUserStore, ["authenticate"]),
    async login() {
      let user = {};
      try {
        const response = await axios.post(
          this.$endpoint + "/user/login",
          {
            email: this.email.toLowerCase().trim(),
            password: this.password.trim(),
          },
          {
            headers: {
              authorization: process.env.VUE_APP_APY_KEY,
            },
          }
        );

        if (response.data.status === "OK") {
          user = response.data.data;

          if (user){
            try {
              await this.authenticate(user);
              await router.push("/dashboard");
            } catch (e) {
              this.login_show_alert = true;
              this.login_alert_msg = "Errore: "+e.message;
            }
          }
        } else {
          this.login_show_alert = true;
          this.login_alert_msg = response.data.message;
        }
      } catch (e) {
        this.login_show_alert = true;
        this.login_alert_msg = "Errore: "+e.message;
      }
    },
  },
};
</script>
