<template>
  <vee-form
    ref="formTube"
    @submit="this.saveTube"
    :validation-schema="this.tubeSchema"
    @invalid-submit="onInvalidSubmit"
  >
    <div class="py-4 container-fluid">
      <div class="col-md-12">
        <div class="row">
          <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h6 class="accordion-header" id="heading-assembledTube">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-assembledTube"
                  aria-expanded="false"
                  aria-controls="collapse-assembledTube"
                >
                  Tubo assemblato
                </button>
              </h6>
              <div
                id="collapse-assembledTube"
                class="accordion-collapse collapse"
                aria-labelledby="heading-assembledTube"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <div class="row">
                    <div class="col-md-3 form-group">
                      <label for="factory_number" class="form-control-label"
                        >Numero di fabbrica</label
                      >
                      <vee-field
                        type="text"
                        class="form-control"
                        name="factory_number"
                        id="factory_number"
                        placeholder="Numero di fabbrica"
                        v-model="this.tube.factory_number"
                      />
                      <error-message
                        class="text-danger text-xs"
                        name="factory_number"
                      />
                    </div>
                    <div class="col-md-3">
                      <label for="client_name" class="form-control-label"
                        >Numero di matricola</label
                      >
                      <vee-field
                        type="text"
                        class="form-control"
                        name="serial_number"
                        id="serial_number"
                        placeholder="Numero di matricola"
                        v-model="this.tube.serial_number"
                      />
                    </div>
                    <div class="col-md-3">
                      <label for="area" class="form-control-label">Area</label>
                      <vee-field
                        type="text"
                        class="form-control"
                        name="area"
                        id="area"
                        placeholder="Area"
                        v-model="this.tube.area"
                      />
                    </div>
                    <div class="col-md-3">
                      <label for="substance" class="form-control-label"
                        >Sostanza</label
                      >
                      <vee-field
                        type="text"
                        class="form-control"
                        name="substance"
                        id="substance"
                        placeholder="Sostanza"
                        v-model="this.tube.substance"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <select-autocomplete
                        v-model:input-model="this.tube.type"
                        :list="this.types"
                        :label="'Tipo'"
                        :placeholder="'Seleziona o digita il Tipo'"
                        :field-name="'type'"
                      ></select-autocomplete>
                      <error-message class="text-danger text-xs" name="type" />
                    </div>
                    <div class="col-md-3">
                      <select-autocomplete
                        v-model:input-model="this.tube.dp"
                        :list="this.dpList"
                        :label="'DP'"
                        :placeholder="'Seleziona o digita il DP'"
                        :field-name="'dp'"
                      ></select-autocomplete>
                      <error-message class="text-danger text-xs" name="dp" />
                    </div>
                    <div class="col-md-3">
                      <label for="lg" class="form-control-label">Lg (mm)</label>
                      <vee-field
                        type="text"
                        class="form-control"
                        name="lg"
                        id="lg"
                        placeholder="Lg"
                        v-model="this.tube.lg"
                      />
                      <error-message class="text-danger text-xs" name="lg" />
                    </div>
                    <div class="col-md-3">
                      <label for="color" class="form-control-label"
                        >Colore</label
                      >
                      <vee-field
                        type="text"
                        class="form-control"
                        name="color"
                        id="color"
                        placeholder="Colore"
                        v-model="this.tube.color"
                      />
                      <error-message class="text-danger text-xs" name="color" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <select-autocomplete
                        v-model:input-model="this.tube.fitting_right"
                        :list="this.fittings"
                        :label="'Raccordo dx'"
                        :placeholder="'Seleziona o digita il raccordo dx'"
                        :field-name="'fitting_right'"
                      ></select-autocomplete>
                      <error-message
                        class="text-danger text-xs"
                        name="fitting_right"
                      />
                    </div>
                    <div class="col-md-4">
                      <select-autocomplete
                        v-model:input-model="this.tube.measure_right"
                        :list="this.measures"
                        :label="'Misura'"
                        :placeholder="'Seleziona o digita la misura'"
                        :field-name="'measure_right'"
                      ></select-autocomplete>
                      <error-message
                        class="text-danger text-xs"
                        name="measure_right"
                      />
                    </div>
                    <div class="col-md-4">
                      <label for="material_right" class="form-control-label"
                        >Materiale</label
                      >
                      <vee-field
                        type="text"
                        class="form-control"
                        name="material_right"
                        id="material_right"
                        placeholder="Materiale"
                        v-model="this.tube.material_right"
                      />
                      <error-message
                        class="text-danger text-xs"
                        name="material_right"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <select-autocomplete
                        v-model:input-model="this.tube.fitting_left"
                        :list="this.fittings"
                        :label="'Raccordo sx'"
                        :placeholder="'Seleziona o digita il raccordo sx'"
                        :field-name="'fitting_left'"
                      ></select-autocomplete>
                      <error-message
                        class="text-danger text-xs"
                        name="fitting_left"
                      />
                    </div>
                    <div class="col-md-4">
                      <select-autocomplete
                        v-model:input-model="this.tube.measure_left"
                        :list="this.measures"
                        :label="'Misura'"
                        :placeholder="'Seleziona o digita la misura'"
                        :field-name="'measure_left'"
                      ></select-autocomplete>
                      <error-message
                        class="text-danger text-xs"
                        name="measure_left"
                      />
                    </div>
                    <div class="col-md-4">
                      <label for="material_left" class="form-control-label"
                        >Materiale</label
                      >
                      <vee-field
                        type="text"
                        class="form-control"
                        name="material_left"
                        id="material_left"
                        placeholder="Materiale"
                        v-model="this.tube.material_left"
                      />
                      <error-message
                        class="text-danger text-xs"
                        name="material_left"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h6 class="accordion-header" id="heading-visual">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-visual"
                  aria-expanded="false"
                  aria-controls="collapse-visual"
                >
                  Esame visivo
                </button>
              </h6>
              <div
                id="collapse-visual"
                class="accordion-collapse collapse"
                aria-labelledby="heading-visual"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <div
                    v-for="(field, key) in tube.visuals"
                    :key="key"
                    class="row"
                  >
                    <tube-visual-form-row
                      :selValues="this.selectedJudgmentValues"
                      :visual-prop="field"
                      :judgments="this.judgments"
                      :notes="this.noteVisualValues"
                      :results="this.resultVisualValues"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h6 class="accordion-header" id="flush-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Sostituzione componenti
                </button>
              </h6>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <div
                    v-for="componentField in tube.components"
                    :key="componentField.slug"
                    class="row mb-2"
                  >
                    <tube-component-form-row
                      :component-prop="componentField"
                      :sel-change="selectedChangeComponentValues"
                      :notes="noteComponentValues"
                    ></tube-component-form-row>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h6 class="accordion-header" id="flush-headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  Test elettrici
                </button>
              </h6>
              <div
                id="flush-collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingFour"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <div
                    v-for="electricalField in tube.electricals"
                    :key="electricalField.slug"
                    class="row mb-2"
                  >
                    <tube-electrical-form-row
                      :electrical-prop="electricalField"
                      :tests="this.testElectricalValues"
                    ></tube-electrical-form-row>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h6 class="accordion-header" id="heading-final">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-final"
                  aria-expanded="false"
                  aria-controls="collapse-final"
                >
                  Giudizio finale
                </button>
              </h6>
              <div
                id="collapse-final"
                class="accordion-collapse collapse"
                aria-labelledby="heading-final"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <div class="row">
                    <h4>Prova idraulica</h4>
                    <div class="col-md-6">
                      <label for="project_pressure" class="form-control-label"
                        >Pressione di progetto</label
                      >
                      <vee-field
                        type="text"
                        class="form-control"
                        name="project_pressure"
                        id="project_pressure"
                        placeholder="Pressione di progetto"
                        v-model="this.tube.project_pressure"
                      />
                    </div>
                    <div class="col-md-6">
                      <label for="test_pressure" class="form-control-label"
                        >Pressione di prova</label
                      >
                      <vee-field
                        type="text"
                        class="form-control"
                        name="test_pressure"
                        id="test_pressure"
                        placeholder="Pressione di prova"
                        v-model="this.tube.test_pressure"
                      />
                    </div>
                    <div class="col-md-12">
                      <label for="hydraulic_result" class="form-control-label"
                        >Risultato della prova</label
                      >
                      <select
                        class="form-select"
                        name="hydraulic_result"
                        id="hydraulic_result"
                        v-model="this.tube.hydraulic_result"
                        @change="this.changeResult"
                      >
                        <option selected value="">Seleziona</option>
                        <option value="positivo">Positivo</option>
                        <option value="negativo">Negativo</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-12">
                      <label class="form-control-label"> Note </label>
                      <textarea
                        v-model="this.tube.note"
                        class="form-control"
                        name="note"
                        id="note"
                        placeholder="Note"
                      ></textarea>
                    </div>
                    <div class="col-md-12">
                      <label for="final_judgement" class="form-control-label"
                        >Giudizio Finale</label
                      >
                      <select
                        class="form-select"
                        name="final_judgement"
                        id="final_judgement"
                        v-model="this.tube.final_judgement"
                        v-if="this.showOptionsFinalJudgement"
                      >
                        <option selected value="">Seleziona</option>
                        <option value="conforme">conforme</option>
                        <option value="non conforme">non conforme</option>
                        <option value="in sospeso">in sospeso</option>
                      </select>

                      <vee-field
                        type="text"
                        class="form-control"
                        name="hydraulic_result"
                        id="hydraulic_result"
                        v-model="this.tube.final_judgement"
                        v-if="!this.showOptionsFinalJudgement"
                        readonly
                      />
                    </div>
                    <div class="col-md-12">
                      <div class="form-check form-switch px-0">
                        <vee-field
                          class="form-check-input my-3 mx-0"
                          type="checkbox"
                          name="result_final"
                          id="result_final"
                          :checked="this.tube.result_final"
                          v-model="this.tube.result_final"
                          :value="true"
                          :unchecked-value="false"
                          @change="this.changeFinalResult"
                        />
                        <label class="form-check-label" for="result_final">
                          Chiudi e Approva certificato
                        </label>
                      </div>
                      <p v-if="this.errorFinalJudgement" class="text-danger">
                        {{ this.errorFinalJudgementMsg }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h6 class="accordion-header" id="flush-headingPhoto">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapsePhoto"
                  aria-expanded="false"
                  aria-controls="flush-collapsePhoto"
                >
                  Scatta fotografia
                </button>
              </h6>
              <div
                id="flush-collapsePhoto"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingPhoto"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <PhotoCamera :tube="this.storeTube.getTube()" />
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <argon-button
              size="sm"
              class="mt-4"
              variant="gradient"
              color="success"
              v-if="
                (!this.commessa.closed || this.user.roles !== 'operatore') &&
                !this.isLoading
              "
            >
              <span v-if="this.tube._id"
                >Salva le modifiche ai dati del tubo</span
              >
              <span v-else>Salva dati tubo</span>
            </argon-button>
            <div v-if="this.isLoading" class="loader"></div>
            <argon-button
              size="sm"
              class="btn btn-sm btn-dark float-right mt-4 mb-0 d-none d-lg-block"
              @click.prevent="this.reset"
              >Annulla
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </vee-form>
</template>
<script>
import ArgonButton from "@/components/ArgonButton.vue";
import router from "@/router";
import axios from "axios";
import TubeVisualFormRow from "@/views/tube/tubeVisualFormRow.vue";
import TubeComponentFormRow from "@/views/tube/tubeComponentFormRow.vue";
import TubeElectricalFormRow from "@/views/tube/tubeElectricalFormRow.vue";
import { ref } from "vue";
import { TubeModel } from "@/models/tube/tube.model";
import PhotoCamera from "@/components/Camera/PhotoCamera.vue";
import useTubeStore from "@/store/tube.store";
import useCommessaStore from "@/store/commessa.store";
import { getItem } from "@/utility/localStorageControl";
import { ErrorMessage } from "vee-validate";
import SelectAutocomplete from "@/views/tube/selectAutocomplete.vue";

export default {
  name: "tube-form",
  data() {
    return {
      selectedValue: "",
      filteredDpList: [],
      showOptions: false,
      showOptionsFinalJudgement: true,
      tubeSchema: {
        factory_number: "required",
        type: "required",
        dp: "required",
        lg: "required",
        color: "required",
        fitting_left: "required",
        fitting_right: "required",
        measure_left: "required",
        measure_right: "required",
        material_left: "required",
        material_right: "required",
        project_pressure: "required",
        test_pressure: "required",
      },
      storeTube: useTubeStore(),
      storeCommessa: useCommessaStore(),
      selectedJudgmentValues: [],
      noteVisualValues: [],
      resultVisualValues: [],
      selectedChangeComponentValues: [],
      noteComponentValues: [],
      testElectricalValues: [],
      commessa: {
        hydraulics: {},
      },
      tube: new TubeModel(),
      types: {},
      dpList: {},
      fittings: {},
      measures: {},
      judgments: {},
      selectedJudgment: [],
      selectedJudgmentValue: "",
      canSaveTube: false,
      visual_list: {},
      component_list: {},
      electrical_list: {},
      tab: 3,
      user: {},
      errorComponents: [],
      errorHydraulicResult: false,
      errorFinalJudgement: false,
      errorFinalJudgementMsg: "",
      isLoading: false,
    };
  },
  props: {
    updateObjects: {
      type: Function,
    },
    tube_prop: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  watch: {
    async tube_prop(newValue) {
      this.tube = await this.storeTube.getTube();
      this.commessa = await this.storeCommessa.getCommessa();
      this.getValuesForUpdate();
    },
  },
  async mounted() {
    this.tube = this.tube_prop;
    this.user = getItem("user");
    await this.initializeForm();
  },
  components: {
    SelectAutocomplete,
    ErrorMessage,
    PhotoCamera,
    TubeElectricalFormRow,
    TubeComponentFormRow,
    TubeVisualFormRow,
    ArgonButton,
  },
  methods: {
    router() {
      return router;
    },
    async getTypeList() {
      try {
        const response = await axios.get(this.$endpoint + "/type/list", {
          headers: {
            authorization: process.env.VUE_APP_APY_KEY,
          },
        });

        if (response.data.status === "OK") {
          this.types = JSON.parse(response.data.data);
          this.types = this.types.filter((item) => item.enabled === true);
        }
        return [];
      } catch (e) {
        console.log(e.toString());
      }
    },
    async getDPList() {
      try {
        const response = await axios.get(this.$endpoint + "/dp/list", {
          headers: {
            authorization: process.env.VUE_APP_APY_KEY,
          },
        });

        if (response.data.status === "OK") {
          this.dpList = JSON.parse(response.data.data);
          this.dpList = this.dpList.filter((item) => item.enabled === true);
        }
        return [];
      } catch (e) {
        console.log(e.toString());
      }
    },
    async getFittingList() {
      try {
        const response = await axios.get(this.$endpoint + "/fitting/list", {
          headers: {
            authorization: process.env.VUE_APP_APY_KEY,
          },
        });

        if (response.data.status === "OK") {
          this.fittings = JSON.parse(response.data.data);
          this.fittings = this.fittings.filter((item) => item.enabled === true);
        }
        return [];
      } catch (e) {
        console.log(e.toString());
      }
    },
    async getMeasureList() {
      try {
        const response = await axios.get(this.$endpoint + "/measure/list", {
          headers: {
            authorization: process.env.VUE_APP_APY_KEY,
          },
        });

        if (response.data.status === "OK") {
          this.measures = JSON.parse(response.data.data);
          this.measures = this.measures.filter((item) => item.enabled === true);
        }
        return [];
      } catch (e) {
        console.log(e.toString());
      }
    },
    async getJudgmentList() {
      try {
        const response = await axios.get(this.$endpoint + "/judgment/list", {
          headers: {
            authorization: process.env.VUE_APP_APY_KEY,
          },
        });

        if (response.data.status === "OK") {
          this.judgments = response.data.data;
          this.judgments = this.judgments.filter(
            (item) => item.enabled === true
          );
        }
      } catch (e) {
        console.log(e.toString());
      }
    },
    async getVisualList() {
      try {
        const response = await axios.get(this.$endpoint + "/visual/list", {
          headers: {
            authorization: process.env.VUE_APP_APY_KEY,
          },
        });

        if (response.data.status === "OK") {
          this.visual_list = JSON.parse(response.data.data);
          this.visual_list = this.visual_list.filter(
            (item) => item.enabled === true
          );
        }
      } catch (e) {
        console.log(e.toString());
      }
    },
    async getComponents() {
      try {
        const response = await axios.get(this.$endpoint + "/component/list", {
          headers: {
            authorization: process.env.VUE_APP_APY_KEY,
          },
        });

        if (response.data.status === "OK") {
          this.component_list = response.data.data;
          this.component_list = this.component_list.filter(
            (item) => item.enabled === true
          );
        }
      } catch (e) {
        console.log(e.toString());
      }
    },
    async getElectricals() {
      try {
        const response = await axios.get(this.$endpoint + "/electrical/list", {
          headers: {
            authorization: process.env.VUE_APP_APY_KEY,
          },
        });

        if (response.data.status === "OK") {
          this.electrical_list = response.data.data;
          this.electrical_list = this.electrical_list.filter(
            (item) => item.enabled === true
          );
        }
      } catch (e) {
        console.log(e.toString());
      }
    },
    async saveTube() {
      this.isLoading = true;
      try {
        //esami visivi
        const visuals = [];
        const selectedJudgment = this.selectedJudgmentValues;
        const noteVisuals = this.noteVisualValues;
        const resultVisuals = this.resultVisualValues;
        this.visual_list.forEach(function (v_item) {
          const elem = {};
          elem.slug = v_item.slug;
          elem.description = v_item.description;
          if (noteVisuals[v_item.slug]) {
            elem.note = noteVisuals[v_item.slug];
          } else {
            elem.note = "";
          }

          if (selectedJudgment[v_item.slug]) {
            elem.judgment = selectedJudgment[v_item.slug];
            if (
              selectedJudgment[v_item.slug].toLowerCase() === "non conforme"
            ) {
              resultVisuals[v_item.slug] = false;
            }
          } else {
            elem.judgment = "";
          }

          if (resultVisuals[v_item.slug]) {
            elem.result = resultVisuals[v_item.slug];
          } else {
            elem.result = false;
          }
          visuals.push(elem);
        });

        //componenti
        const components = [];
        const selectedChangeComponents = this.selectedChangeComponentValues;
        const noteComponents = this.noteComponentValues;
        this.component_list.forEach(function (c_item) {
          const elem = {};
          elem.slug = c_item.slug;
          elem.description = c_item.description;
          if (noteComponents[c_item.slug]) {
            elem.note = noteComponents[c_item.slug];
          } else {
            elem.note = "";
          }

          if (selectedChangeComponents[c_item.slug]) {
            elem.change = selectedChangeComponents[c_item.slug];
          } else {
            elem.change = "";
          }
          components.push(elem);
        });

        //test elettrici
        const electricals = [];
        const testElectricals = this.testElectricalValues;
        this.electrical_list.forEach(function (e_item) {
          const elem = {};
          elem.slug = e_item.slug;
          elem.description = e_item.description;
          if (testElectricals[e_item.slug]) {
            elem.test = testElectricals[e_item.slug];
          } else {
            elem.test = "";
          }
          electricals.push(elem);
        });

        this.tube.visuals = visuals;
        this.tube.components = components;
        this.tube.electricals = electricals;

        if (!this.checkTubeData()) {
          //form non valido
          if (this.errorComponents.length > 0) {
            let errorMsg =
              "Errore nel savataggio del tubo.<br>Le note dei seguenti componenti sono obbligatorie: " +
              "<ul>";
            this.errorComponents.forEach(function (componentName) {
              errorMsg = errorMsg + "<li>" + componentName + "</li>";
            });
            errorMsg = errorMsg + "</ul>";

            this.$toast.open({
              message: errorMsg,
              duration: 5000,
              position: "top",
              type: "error",
            });
          }

          if (this.errorHydraulicResult) {
            this.$toast.open({
              message:
                "È obbligatorio indicare il risultato della prova idraulica.",
              duration: 5000,
              position: "top",
              type: "error",
            });
            this.errorHydraulicResult = false;
          }
        } else {
          let countTube = 0;
          if (this.tube.tube_number === undefined) {
            //tubo nuovo
            countTube = this.commessa.count_tube + 1;
            this.commessa.count_tube = countTube;
            this.tube.tube_number = countTube;
            await this.storeCommessa.setCommessa(this.commessa);
          }

          this.tube.commessaId = this.commessa._id;
          this.tube.hydraulics = this.commessa.hydraulics;
          this.tube.userId = this.user._id;

          const tubeImage = this.getBase64StringFromImage(this.tube.picture);
          this.tube.picture = {};

          const responseTube = await axios.post(
            this.$endpoint + "/commessa/add/tube",
            {
              tube: this.tube,
            },
            {
              headers: {
                authorization: process.env.VUE_APP_APY_KEY,
              },
            }
          );

          //se si è in aggiunta di un tubo nuovo l'_id non c'è ancora, va preso dalla risposta dell'API che salva il nuovo tubo
          if (this.tube._id === undefined) {
            this.tube = responseTube.data.data;
          }

          if (responseTube.data.status === "OK") {
            //Aggiungere la foto
            if (tubeImage?.length > 0) {
              const fotoBlob = this.dataURLToBlob(tubeImage); // Converti il Data URL in Blob
              const formData = new FormData();
              formData.append("file", fotoBlob, "immagine.jpg");
              formData.append("tube_id", this.tube._id);
              formData.append("user_id", this.user._id);
              const resultFoto = await axios.post(
                this.$endpoint + "/commessa/tube/addpicture",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    authorization: process.env.VUE_APP_APY_KEY,
                  },
                }
              );
              const newTube = resultFoto.data.data;
              await this.storeTube.addTubeToList(newTube);
              await this.storeTube.setTube(newTube);
            } else {
              await this.storeTube.addTubeToList(responseTube.data.data);
              await this.storeTube.setTube(responseTube.data.data);
            }

            await new Promise((resolve) => setTimeout(resolve, 2000)); // Simula un ritardo
            const successMsg =
              "Tubo salvato con successo <br> Risultato della prova idraulica: <span class='text-uppercase'>" +
              this.tube.hydraulic_result +
              "</span>";
            // await this.clearForm();
            this.$toast.open({
              message: successMsg,
              duration: 5000,
              position: "top",
              type: "success",
            });
            await this.reset();
          } else {
            this.$toast.open({
              message: "Errore nel salvataggio del tubo",
              duration: 5000,
              position: "top",
              type: "error",
            });
          }
        }
      } catch (e) {
        this.$toast.open({
          message: "Exception: " + e.message,
          duration: 5000,
          position: "top",
          type: "error",
        });
        console.log(e.toString());
      }
    },
    dataURLToBlob(dataUrl) {
      const arr = dataUrl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    async clearForm() {
      this.tube = new TubeModel();
      await this.storeTube.setTube(this.tube);
      await this.initializeForm();
      this.isLoading = false;
      this.updateObjects(this.tube, 3, this.tubes);
    },

    async initializeForm() {
      await this.getTypeList();
      await this.getDPList();
      await this.getFittingList();
      await this.getMeasureList();
      await this.getVisualList();
      await this.getJudgmentList();
      await this.getComponents();
      await this.getElectricals();
      try {
        const formDataVisuals = [];
        this.visual_list.forEach(function (v_item) {
          let item = {
            slug: v_item.slug,
            description: v_item.description,
            judgment: "",
            note: "",
            result: "",
          };
          formDataVisuals.push(item);
        });
        this.tube.visuals = formDataVisuals;
      } catch (error) {
        console.error("Errore visual:", error);
      }

      try {
        const formDataComponents = [];
        this.component_list.forEach(function (c_item) {
          let item = {
            slug: c_item.slug,
            description: c_item.description,
            change: "",
            note: "",
          };
          formDataComponents.push(item);
        });
        this.tube.components = formDataComponents;
      } catch (error) {
        console.error("Errore componenti:", error);
      }

      try {
        const formDataElectricals = [];
        this.electrical_list.forEach(function (e_item) {
          let item = {
            slug: e_item.slug,
            description: e_item.description,
            test: "",
          };
          formDataElectricals.push(item);
        });
        this.tube.electricals = formDataElectricals;
      } catch (error) {
        console.error("Errore test elettrici:", error);
      }

      if (this.tube._id === undefined) {
        this.tube.type = "";
        this.tube.dp = "";
        this.tube.fitting_left = "";
        this.tube.fitting_right = "";
        this.tube.measure_left = "";
        this.tube.measure_right = "";
        this.tube.hydraulic_result = "";

        if (this.commessa.hydraulics.length > 0) {
          this.commessa.hydraulics.forEach((hydraulic) => {
            if (hydraulic.slug === "pressione-di-progetto") {
              this.tube.project_pressure = hydraulic.value;
            } else if (hydraulic.slug === "pressione-di-prova") {
              this.tube.test_pressure = hydraulic.value;
            }
          });
        }
        this.canSaveTube = true;
      }
    },
    async reset() {
      await this.clearForm();
      this.updateObjects(this.tube, 2);
    },
    getValuesForUpdate() {
      // Parte esame visivo
      const dynamicJudgmentValues = ref([]);
      const dynamicNoteValues = ref([]);
      const dynamicResultValues = ref([]);

      this.tube.visuals?.forEach((item) => {
        dynamicJudgmentValues.value[item.slug] = item.judgment
          ? item.judgment
          : "";
        dynamicNoteValues.value[item.slug] = item.note;
        dynamicResultValues.value[item.slug] = item.result;
      });
      this.selectedJudgmentValues = dynamicJudgmentValues.value;
      this.noteVisualValues = dynamicNoteValues.value;
      this.resultVisualValues = dynamicResultValues.value;

      // Parte dei componenti
      const dynamicComponentValues = ref([]);
      const dynamicComponentNoteValues = ref([]);

      this.tube.components?.forEach((item) => {
        dynamicComponentValues.value[item.slug] = item.change
          ? item.change
          : "";
        dynamicComponentNoteValues.value[item.slug] = item.note;
      });

      this.selectedChangeComponentValues = dynamicComponentValues.value;
      this.noteComponentValues = dynamicComponentNoteValues.value;

      // Parte del form TEST ELETTRICI
      const dynamicElectricals = ref([]);

      this.tube.electricals?.forEach((item) => {
        dynamicElectricals.value[item.slug] = item.test;
      });
      this.testElectricalValues = dynamicElectricals.value;

      if (this.tube.hydraulic_result === "negativo" && this.tube.result_final) {
        this.showOptionsFinalJudgement = false;
      }
    },
    checkTubeData() {
      if (!this.tube.result_final) {
        return true;
      }

      if (!this.tube.final_judgement && this.tube.result_final) {
        this.errorFinalJudgementMsg = "Selezionare il giudizio finale";
        this.errorFinalJudgement = true;
        this.tube.result_final = false;
        return false;
      }

      const errorMsgComponents = [];
      this.tube.components.forEach(function (component) {
        if (
          component.change.toLowerCase() === "si" &&
          component.note.trim() === ""
        ) {
          errorMsgComponents.push(component.description);
        }
      });
      this.errorComponents = errorMsgComponents;

      if (this.tube.hydraulic_result === "") {
        this.errorHydraulicResult = true;
      } else if (this.tube.hydraulic_result === "negativo") {
        this.tube.final_judgement = "non conforme";
        this.showOptionsFinalJudgement = false;
      }

      if (
        this.tube.final_judgement === "in sospeso" &&
        this.tube.result_final
      ) {
        this.errorFinalJudgement = true;
        this.errorFinalJudgementMsg = "Il giudizio finale è IN SOSPESO";
      }
      return (
        errorMsgComponents.length <= 0 &&
        !this.errorHydraulicResult &&
        !this.errorFinalJudgement
      );
    },
    onInvalidSubmit({ values, errors, results }) {
      let stringError = "Si prega di correggere gli errori nel modulo.<br>";

      this.$toast.open({
        message: stringError,
        duration: 5000,
        position: "top",
        type: "error",
      });
    },
    getBase64StringFromImage(imageData) {
      if (this.isObject(imageData)) {
        if ("buffer" in imageData) {
          if (this.isObject(imageData.buffer)) {
            if ("data" in imageData.buffer) {
              return (
                "data:" +
                imageData.mimetype +
                ";base64," +
                imageData?.buffer?.data
              );
            }
          } else {
            return (
              "data:" + imageData.mimetype + ";base64," + imageData?.buffer
            );
          }
        } else {
          return "";
        }
      }
      return imageData;
    },
    isObject(prop) {
      return typeof prop === "object" && prop !== null && !Array.isArray(prop);
    },
    changeResult() {
      if (this.tube.result_final) {
        if (this.tube.hydraulic_result === "negativo") {
          this.tube.final_judgement = "non conforme";
          this.showOptionsFinalJudgement = false;
        } else {
          this.showOptionsFinalJudgement = true;
        }
      }
    },
    changeFinalResult() {
      if (
        this.tube.final_judgement === "in sospeso" &&
        this.tube.result_final
      ) {
        this.errorFinalJudgement = true;
        this.tube.result_final = false;
      } else {
        this.errorFinalJudgement = false;
      }

      if (this.tube.result_final && this.tube.hydraulic_result === "negativo") {
        this.tube.final_judgement = "non conforme";
        this.showOptionsFinalJudgement = false;
      } else {
        this.showOptionsFinalJudgement = true;
      }
    },
  },
};
</script>

<style>
.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
