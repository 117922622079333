<template>
  <div class="card">
    <div class="card-body px-0 pt-0 pb-2">
      <argon-alert color="success" v-if="show_success" class="mx-2 mt-2"
        >{{ alert_msg }}
      </argon-alert>
      <div class="table-responsive p-0" v-if="hydraulicsList">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Descrizione
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Stato
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Data creazione
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="hydraulic in hydraulicsList" :key="hydraulic._id">
              <table-row :item="hydraulic" />
              <td class="align-middle">
                <a
                  @click="editHydraulics(hydraulic._id)"
                  class="text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Modifica"
                  href="javascript:;"
                >
                  <i class="fa fa-pen"></i
                ></a>
                <a
                  @click.stop="openModal(hydraulic._id)"
                  class="text-secondary font-weight-bold text-xs ms-2"
                  data-toggle="tooltip"
                  data-original-title="Elimina"
                  href="javascript:;"
                >
                  <i class="fa fa-trash"></i
                ></a>
                <delete-modal
                  :modalId="'deleteModal' + hydraulic._id"
                  :object="hydraulic"
                  @deleteObject="deleteHydraulic"
                  @closeModal="closeModal(hydraulic._id)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import TableRow from "@/components/TableRow.vue";
import router from "@/router";
import DeleteModal from "@/components/deleteModal.vue";
import axios from "axios";
import ArgonAlert from "@/components/ArgonAlert.vue";

export default {
  name: "hydraulics-table",
  props: {
    hydraulics: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    // pages: {type: Number},
    // total: {type: Number},
    // pagination_size: {type: Number},
  },
  data() {
    return {
      show_success: false,
      alert_msg: "",
      hydraulicsList: {}
    };
  },
  components: { ArgonAlert, DeleteModal, TableRow },
  mounted() {
    this.hydraulicsList = this.hydraulics;
  },
  methods: {
    editHydraulics(hydraulicId) {
      router.push({ name: "prova-idraulica-edit", params: { id: hydraulicId } });
    },
    openModal(id) {
      this.$vfm.open("deleteModal" + id);
    },
    async closeModal(id) {
      await router.push({ name: "Lista prove idrauliche" });
      await this.$vfm.close("deleteModal" + id);
    },
    async deleteHydraulic(hydraulic) {
      this.$emit("deleteObject", hydraulic);
      const response = await axios.delete(
        this.$endpoint + "/hydraulics/delete/" + hydraulic._id,
        {
          headers: {
            authorization: process.env.VUE_APP_APY_KEY,
          },
        }
      );

      if (response.data.status === "OK") {
        this.show_success = true;
        this.alert_msg = "Prova idraulica eliminata con successo";
        const index = this.hydraulicsList.findIndex(obj => obj._id === hydraulic._id) // find the post index
        if (~index) // if the post exists in array
          this.hydraulicsList.splice(index, 1) //delete the post
      }

      await this.closeModal(hydraulic._id);
    },
  },
};
</script>
