<template>
  <div class="container-fluid">
    <div class="card shadow-lg mt-3">
      <div class="card-body p-3">
        <div class="row gx-4">
          <header-title
            :id="this.idDP"
            :title="'Nuovo Dim. Tubo'"
            :edit-title="'Modifica Dim. Tubo'"
          ></header-title>
          <div
            class="mx-auto mt-3 col-lg-3 col-sm-3 my-sm-auto ms-sm-auto me-sm-0"
          >
            <div class="nav-wrapper position-relative end-0">
              <ul
                class="p-1 bg-transparent nav nav-pills nav-fill"
                role="tablist"
              >
                <li class="nav-item">
                  <back-to-list :url="'/dp-list'"></back-to-list>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <vee-form :validation-schema="dpSchema" @submit="saveDP">
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <argon-alert color="danger" v-if="show_alert"
                >{{ alert_msg }}
              </argon-alert>
              <argon-alert color="success" v-if="show_success"
                >{{ alert_msg }}
              </argon-alert>
              <div class="row">
                <div class="col-md-12">
                  <label for="example-text-input" class="form-control-label"
                    >Descrizione</label
                  >
                  <vee-field
                    type="text"
                    class="form-control"
                    name="description"
                    id="description"
                    v-model="this.dp.description"
                  />
                  <div class="form-check form-switch mt-2">
                    <vee-field
                      class="form-check-input"
                      type="checkbox"
                      id="enabled"
                      name="enabled"
                      :checked="this.dp.enabled"
                      v-model="this.dp.enabled"
                      :value="true"
                      :unchecked-value="false"
                    />
                    <label class="form-check-label" :for="this.dp.enabled">
                      Abilita
                    </label>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <argon-button
                  size="sm"
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  >Salva
                </argon-button>
                <router-link
                  to="/dp-list"
                  class="btn btn-sm btn-dark float-right mt-4 mb-0 d-none d-lg-block"
                >
                  Annulla
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </vee-form>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import axios from "axios";
import ArgonAlert from "@/components/ArgonAlert.vue";
import router from "@/router";
import HeaderTitle from "@/components/Header/HeaderTitle.vue";
import BackToList from "@/components/Header/BackToList.vue";

export default {
  name: "edit-dp",
  data() {
    return {
      dpSchema: {
        description: "required",
      },
      show_alert: false,
      show_success: false,
      alert_msg: "",
      dp: {
        description: "",
        enabled: false,
      },
      idDP: "",
    };
  },
  beforeMount() {
    this.idDP = this.$route.params.id;
  },
  async mounted() {
    await this.getDP();
  },
  components: {
    BackToList,
    HeaderTitle,
    ArgonAlert,
    ArgonButton,
  },
  methods: {
    router() {
      return router;
    },
    async getDP() {
      try {
        if (this.idDP) {
          const response = await axios.get(
            this.$endpoint + "/dp/show/" + this.idDP,
            {
              headers: {
                authorization: process.env.VUE_APP_APY_KEY,
              },
            }
          );

          const dp = JSON.parse(response.data.data);
          if (response.data.status === "OK") {
            this.dp.description = dp.description;
            this.dp.enabled = dp.enabled;
          }
        }
      } catch (e) {
        console.log(e.toString());
      }
    },
    async saveDP(values) {
      try {
        const response = await axios.post(
          this.$endpoint + "/dp/add",
          {
            description: values.description.trim(),
            enabled: values.enabled,
          },
          {
            params: { id: this.idDP },
            headers: {
              authorization: process.env.VUE_APP_APY_KEY,
            },
          }
        );

        if (response.data.status === "OK") {
          this.show_success = true;
          this.alert_msg = "DP salvato con successo";
        } else {
          this.show_alert = true;
          this.alert_msg = "Errore nel salvataggio";
        }
      } catch (e) {
        this.show_alert = true;
        this.alert_msg = "Errore nel salvataggio";
      }
    },
  },
};
</script>
