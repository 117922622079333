<template>
  <div class="py-4 container-fluid">
    <div class="row mt-2">
      <div class="col-lg-12 mb-4">
        <div class="card">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-2">Commesse aperte</h6>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table align-items-center">
              <tbody>
                <tr v-for="(commessa, index) in commesse" :key="index">
                  <td class="w-30">
                    <div class="py-1 d-flex align-items-center">
                      <div class="ms-4">
                        <p class="mb-0 text-xs font-weight-bold">Numero:</p>
                        <h6 class="mb-0 text-sm"><a
                          @click="editCommessa(commessa._id)"
                          class="text-secondary font-weight-bold text-xs me2"
                          data-toggle="tooltip"
                          data-original-title="Modifica"
                          href="javascript:;"
                        >
                          <i class="fa fa-pen"></i
                        ></a>
                        {{ commessa.number }}</h6>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                      <p class="mb-0 text-xs font-weight-bold">Cliente:</p>
                      <h6 class="mb-0 text-sm">{{ commessa.client_name }}</h6>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                      <p class="mb-0 text-xs font-weight-bold">Ordine n.:</p>
                      <h6 class="mb-0 text-sm">{{ commessa.order_number }}</h6>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                      <h6 class="mb-0 text-sm">
                        <!--                        <a-->
                        <!--                          @click="editCommessa(commessa._id)"-->
                        <!--                          class="text-secondary font-weight-bold text-xs"-->
                        <!--                          data-toggle="tooltip"-->
                        <!--                          data-original-title="Modifica"-->
                        <!--                          href="javascript:;"-->
                        <!--                        >-->
                        <!--                          <i class="fa fa-pen"></i-->
                        <!--                        ></a>-->
                        <commessa-delete-btn
                          :commessa-prop="commessa"
                          :commesse="this.commesse"
                          :url="'Dashboard'"
                        ></commessa-delete-btn>
                      </h6>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="this.user?.roles === 'superadmin'">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.componenti.title"
              :value="stats.componenti.value"
              :percentage="stats.componenti.percentage"
              :iconClass="stats.componenti.iconClass"
              :iconBackground="stats.componenti.iconBackground"
              :detail="stats.componenti.detail"
              directionReverse
              :route="stats.componenti.route"
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.dp.title"
              :value="stats.dp.value"
              :percentage="stats.dp.percentage"
              :iconClass="stats.dp.iconClass"
              :iconBackground="stats.dp.iconBackground"
              :detail="stats.dp.detail"
              directionReverse
              :route="stats.dp.route"
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.electrical.title"
              :value="stats.electrical.value"
              :percentage="stats.electrical.percentage"
              :iconClass="stats.electrical.iconClass"
              :iconBackground="stats.electrical.iconBackground"
              :detail="stats.electrical.detail"
              directionReverse
              :route="stats.electrical.route"
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.fitting.title"
              :value="stats.fitting.value"
              :percentage="stats.fitting.percentage"
              :iconClass="stats.fitting.iconClass"
              :iconBackground="stats.fitting.iconBackground"
              :detail="stats.fitting.detail"
              directionReverse
              :route="stats.fitting.route"
            ></card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.hydraulics.title"
              :value="stats.hydraulics.value"
              :percentage="stats.hydraulics.percentage"
              :iconClass="stats.hydraulics.iconClass"
              :iconBackground="stats.hydraulics.iconBackground"
              :detail="stats.hydraulics.detail"
              directionReverse
              :route="stats.hydraulics.route"
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.judgment.title"
              :value="stats.judgment.value"
              :percentage="stats.judgment.percentage"
              :iconClass="stats.judgment.iconClass"
              :iconBackground="stats.judgment.iconBackground"
              :detail="stats.judgment.detail"
              directionReverse
              :route="stats.judgment.route"
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.measure.title"
              :value="stats.measure.value"
              :percentage="stats.measure.percentage"
              :iconClass="stats.measure.iconClass"
              :iconBackground="stats.measure.iconBackground"
              :detail="stats.measure.detail"
              directionReverse
              :route="stats.measure.route"
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.type.title"
              :value="stats.type.value"
              :percentage="stats.type.percentage"
              :iconClass="stats.type.iconClass"
              :iconBackground="stats.type.iconBackground"
              :detail="stats.type.detail"
              directionReverse
              :route="stats.type.route"
            ></card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.visual.title"
              :value="stats.visual.value"
              :percentage="stats.visual.percentage"
              :iconClass="stats.visual.iconClass"
              :iconBackground="stats.visual.iconBackground"
              :detail="stats.visual.detail"
              directionReverse
              :route="stats.visual.route"
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.user.title"
              :value="stats.user.value"
              :percentage="stats.user.percentage"
              :iconClass="stats.user.iconClass"
              :iconBackground="stats.user.iconBackground"
              :detail="stats.user.detail"
              directionReverse
              :route="stats.user.route"
            ></card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/components/Cards/Card.vue";
import axios from "axios";
import router from "@/router";
import { mapActions } from "pinia";
import useCommessaStore from "@/store/commessa.store";
import CommessaDeleteBtn from "@/views/commessa/commessaDeleteBtn.vue";

export default {
  name: "dashboard-default",
  data() {
    return {
      stats: {
        componenti: {
          title: "",
          value: "Componenti",
          percentage: "",
          iconClass: "ni ni-archive-2",
          detail: "Gestione elenco dei componenti",
          iconBackground: "bg-gradient-warning",
          route: "componenti",
        },
        dp: {
          title: "",
          value: "Dim. Tubo",
          percentage: "",
          iconClass: "ni ni-archive-2",
          iconBackground: "bg-gradient-warning",
          detail: "Gestione elenco delle Dim. Tubo",
          route: "dp-list",
        },
        electrical: {
          title: "",
          value: "Test elettrici",
          percentage: "",
          iconClass: "ni ni-archive-2",
          iconBackground: "bg-gradient-warning",
          detail: "Gestione elenco dei test elettrici",
          route: "test-elettrici-list",
        },
        fitting: {
          title: "",
          value: "Raccordi",
          percentage: "",
          iconClass: "ni ni-archive-2",
          iconBackground: "bg-gradient-warning",
          detail: "Gestione elenco dei raccordi",
          route: "raccordi-list",
        },
        hydraulics: {
          title: "",
          value: "Prove idrauliche",
          percentage: "",
          iconClass: "ni ni-archive-2",
          iconBackground: "bg-gradient-warning",
          detail: "Gestione elenco delle prove idrauliche",
          route: "prova-idraulica-list",
        },
        judgment: {
          title: "",
          value: "Giudizi",
          percentage: "",
          iconClass: "ni ni-archive-2",
          iconBackground: "bg-gradient-warning",
          detail: "Gestione elenco dei giudizi",
          route: "/giudizi-list",
        },
        measure: {
          title: "",
          value: "Dim. Raccordo",
          percentage: "",
          iconClass: "ni ni-archive-2",
          iconBackground: "bg-gradient-warning",
          detail: "Gestione elenco delle Dim. Raccordo",
          route: "/misure-list",
        },
        type: {
          title: "",
          value: "Tipi",
          percentage: "",
          iconClass: "ni ni-archive-2",
          iconBackground: "bg-gradient-warning",
          detail: "Gestione elenco dei tipi",
          route: "/tipi-list",
        },
        visual: {
          title: "",
          value: "Esami visivi",
          percentage: "",
          iconClass: "ni ni-archive-2",
          iconBackground: "bg-gradient-warning",
          detail: "Gestione elenco degli esami visivi",
          route: "/esami-visivi-list",
        },
        user: {
          title: "",
          value: "Utenti",
          percentage: "",
          iconClass: "ni ni-archive-2",
          iconBackground: "bg-gradient-warning",
          detail: "Gestione elenco degli utenti",
          route: "/user-list",
        },
      },
      commesse: {},
      user: {},
    };
  },
  components: {
    CommessaDeleteBtn,
    Card,
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));

    if (!this.user) {
      router.push("/login");
      return;
    }
    this.getCommesse();
  },
  methods: {
    ...mapActions(useCommessaStore, ["setCommessaList"]),
    async getCommesse() {
      try {
        const store = useCommessaStore();
        const response = await axios.get(
          this.$endpoint + "/commessa/open/list",
          {
            headers: {
              authorization: process.env.VUE_APP_APY_KEY,
            },
          }
        );

        this.commesse = JSON.parse(response.data.data);
        await store.setCommessaList(this.commesse);
      } catch (ex) {
        console.log(ex.message);
      }
    },
    editCommessa(commessaId) {
      router.push({ name: "Commessa", params: { id: commessaId } });
    },
  },
};
</script>
