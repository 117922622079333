import { createRouter, createWebHistory } from "vue-router";
import login from "../views/login/login.vue";
import Componenti from "../views/component/componentsList.vue";
import ComponenteEdit from "../views/component/componentEdit.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import DpList from "@/views/dp/dpList.vue";
import DpEdit from "@/views/dp/dpEdit.vue";
import ElectricalList from "@/views/electrical/electricalList.vue";
import ElectricalEdit from "@/views/electrical/electricalEdit.vue";
import FittingList from "@/views/fitting/fittingList.vue";
import FittingEdit from "@/views/fitting/fittingEdit.vue";
import hydraulicsList from "@/views/hydraulics/hydraulicsList.vue";
import hydraulicsEdit from "@/views/hydraulics/hydraulicsEdit.vue";
import JudgmentList from "@/views/judgment/judgmentList.vue";
import JudgmentEdit from "@/views/judgment/judgmentEdit.vue";
import MeasureList from "@/views/measure/measureList.vue";
import MeasureEdit from "@/views/measure/measureEdit.vue";
import TypeList from "@/views/type/typeList.vue";
import TypeEdit from "@/views/type/typeEdit.vue";
import VisualList from "@/views/visual/visualList.vue";
import VisualEdit from "@/views/visual/visualEdit.vue";
import CommessaAdd from "@/views/commessa/commessaAdd.vue";
import CommessaList from "@/views/commessa/commessaList.vue";
import UserList from "@/views/user/userList.vue";
import UserEdit from "@/views/user/userEdit.vue";
import tubeImage from "@/views/tube/tubeImage.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/componenti",
    name: "componenti",
    component: Componenti,
  },
  {
    path: "/componente/:id?",
    name: "componenteedit",
    component: ComponenteEdit,
  },
  {
    path: "/dp-list",
    name: "Lista Dim. Tubo",
    component: DpList,
  },
  {
    path: "/dp/:id?",
    name: "dpedit",
    component: DpEdit,
  },
  {
    path: "/test-elettrici-list",
    name: "Lista test elettrici",
    component: ElectricalList,
  },
  {
    path: "/test-elettrici/:id?",
    name: "Modifica test elettrici",
    component: ElectricalEdit,
  },
  {
    path: "/raccordi-list",
    name: "raccordi-list",
    component: FittingList,
  },
  {
    path: "/raccordi/:id?",
    name: "raccordi-edit",
    component: FittingEdit,
  },
  {
    path: "/prova-idraulica-list",
    name: "Lista prove idrauliche",
    component: hydraulicsList,
  },
  {
    path: "/prova-idraulica/:id?",
    name: "prova-idraulica-edit",
    component: hydraulicsEdit,
  },
  {
    path: "/giudizi-list",
    name: "giudizi-list",
    component: JudgmentList,
  },
  {
    path: "/giudizi/:id?",
    name: "giudizi-edit",
    component: JudgmentEdit,
  },
  {
    path: "/misure-list",
    name: "Lista Dim. Raccordo",
    component: MeasureList,
  },
  {
    path: "/misure/:id?",
    name: "misure-edit",
    component: MeasureEdit,
  },
  {
    path: "/tipi-list",
    name: "Lista tipi",
    component: TypeList,
  },
  {
    path: "/tipi/:id?",
    name: "tipi-edit",
    component: TypeEdit,
  },
  {
    path: "/esami-visivi-list",
    name: "Lista esami visivi",
    component: VisualList,
  },
  {
    path: "/esami-visivi/:id?",
    name: "esami-visivi-edit",
    component: VisualEdit,
  },
  {
    path: "/commessa-add",
    name: "Nuova commessa",
    component: CommessaAdd,
  },
  {
    path: "/commessa/:id?",
    name: "Commessa",
    component: CommessaAdd,
  },
  {
    path: "/commesse",
    name: "Lista commesse",
    component: CommessaList,
  },
  {
    path: "/login",
    name: "Login",
    component: login,
  },
  {
    path: "/user-list",
    name: "Lista utenti",
    component: UserList,
  },
  {
    path: "/user/:id?",
    name: "user-edit",
    component: UserEdit,
  },
  {
    path: "/image/:id?",
    name: "image",
    component: tubeImage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
