<template>
  <div>
    <VueFinalModal
      name="deleteModal"
      :modal-id="this.modalId"
      :hide-overlay="true"
      class="modal-padding-centered modal-dialog justify-center items-center modal-dialog-centered"
      content-class="modal-content max-w-xl mx-4 p-4 bg-white"
    >
      <h5 class="text-center fw-bold">Attenzione</h5>
      <div class="col-12 text-center">
        Sei sicuro di voler eliminare l'oggetto selezionato?
      </div>
      <div class="col-12">
        <p>
          <b>{{ object.description }}</b>
        </p>
      </div>
      <div class="col-12">
        <div class="row mt-2">
          <div class="col-12">
            <button class="btn btn-danger full-width mx-2" @click="confirm">
              Elimina
            </button>
            <button class="btn btn-primary full-width" @click="close">
              Chiudi
            </button>
          </div>
        </div>
      </div>
    </VueFinalModal>
  </div>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";

export default {
  name: "deleteModal",
  props: {
    modalId: { type: String },
    object: {},
  },
  components: { VueFinalModal },
  methods: {
    confirm() {
      this.$emit("deleteObject", this.object);
    },
    close() {
      this.$emit("closeModal");
    },
  },
};
</script>
