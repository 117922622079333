<template>
  <div class="py-4 container-fluid">
    <div class="card shadow-lg mt-n4">
      <div class="card-body p-3">
        <div class="row gx-4 mb-2">
          <header-title :title="'Lista Dim. Raccordo'"></header-title>
          <div
            class="mx-auto mt-3 col-lg-3 col-md-3 my-sm-auto ms-sm-auto me-sm-0 ps-0"
          >
            <div class="nav-wrapper position-relative end-0">
              <ul
                class="p-1 bg-transparent nav nav-pills nav-fill"
                role="tablist"
              >
                <li class="nav-item me-md-4"></li>
                <li class="nav-item ms-3">
                  <a
                    class="mb-0 btn btn-outline-warning btn-sm text-sm text-right"
                    data-bs-toggle="tab"
                    href="javascript:;"
                    role="tab"
                    aria-selected="true"
                    @click="addMeasure()"
                  >
                    <span><i class="fa fa-plus me-1"></i>Aggiungi</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <vee-form @submit="searchMeasure" class="form-inline">
              <div class="form-group mb-2">
                <vee-field type="text" name="cerca" class="search-field" />
                <argon-button
                  size="sm"
                  class="float-end"
                  variant="gradient"
                  color="primary"
                  >Cerca
                </argon-button>
              </div>
            </vee-form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <measure-table
          :measures="measures"
          v-if="this.measures && !this.search_measure"
        />
        <div v-if="total > pagination_size" class="col-12">
          <app-pagination
            :total-items="total"
            :items-per-page="pagination_size"
            @getPaginationItems="getPaginationMeasure"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import ArgonButton from "@/components/ArgonButton.vue";
import AppPagination from "@/components/Pagination.vue";
import { setItem, getItem } from "@/utility/localStorageControl";
import MeasureTable from "@/views/measure/measureTable.vue";
import HeaderTitle from "@/components/Header/HeaderTitle.vue";

export default {
  name: "list-measure",
  components: {
    HeaderTitle,
    MeasureTable,
    AppPagination,
    ArgonButton,
  },
  data() {
    return {
      measures: null,
      search_measure: false,
      pages: 0,
      total: 0,
      pagination_size: 1,
    };
  },
  mounted() {
    this.getMeasures();
  },
  methods: {
    // ...mapActions(searchDataStore, ["setSearchData", "getSearchData"]),
    async getMeasures() {
      try {
        const response = await axios.get(this.$endpoint + "/measure/list", {
          params: {
            page: 1,
            size: this.pagination_size,
          },
          headers: {
            authorization: process.env.VUE_APP_APY_KEY,
          },
        });

        this.measures = JSON.parse(response.data.data);
        this.total = response.data.total;
      } catch (ex) {
        console.log(ex);
      }
    },
    addMeasure() {
      router.push({ name: "misure-edit" });
    },
    async searchMeasure(searchValue) {
      let loader;
      try {
        loader = this.$loading.show({
          loader: "dots",
          color: "#2dce89",
        });
        this.search_measure = true;
        const response = await axios.get(this.$endpoint + "/measure/list", {
          params: { search: searchValue.cerca },
          headers: {
            authorization: process.env.VUE_APP_APY_KEY,
          },
        });
        this.measures = JSON.parse(response.data.data);
        this.search_measure = false;

        await setItem("search_measure", searchValue.cerca);
        loader.hide();
      } catch (ex) {
        console.log(ex);
      }
    },
    async getPaginationMeasure(page) {
      try {
        let searchDataInStore = await getItem("search_measure");
        // if (Object.keys(searchDataInStore).length === 0) {
        //   searchDataInStore = {
        //     ownerid: this.user_data.sfid,
        //     shop: this.shop_data.id,
        //   };
        // }
        const response = await axios.post(
          this.$endpoint + "/measure/list",
          searchDataInStore,
          {
            params: {
              size: this.pagination_size,
              search: searchDataInStore,
            },
            headers: {
              authorization: process.env.VUE_APP_APY_KEY,
            },
          }
        );
        this.measures = JSON.parse(response.data.data);
        this.total = response.data.total;
      } catch (ex) {
        console.log(ex);
      }
    },
  },
};
</script>

<style>
ul li input[type="text"] {
  display: block;
}

.search-field {
  display: inline;
  width: 85%;
  padding: 0.5rem 0.75rem;
  margin-right: 0.5rem;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.8rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d2d6da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.5rem;
  transition: box-shadow 0.15s ease, border-color 0.15s ease;
}

.text-right {
  text-align: right;
}
</style>
