<template>
  <td>
    <div class="d-flex px-2 py-1">
      <a
        href="#"
        @click.prevent="this.getPdf(item)"
        class="text-secondary font-weight-bold cursor-pointer me-2"
        v-if="!this.commessa.closed"
      >
        <i class="fas fa-file-pdf"></i>
      </a>
      <span class="text-secondary text-xs font-weight-bold align-middle mt-1">
        <i
          class="fa fa-solid fa-circle text-danger"
          v-if="item.final_judgement === 'non conforme'"
        ></i>
        <i
          class="fa fa-solid fa-circle text-success"
          v-if="item.final_judgement === 'conforme'"
        ></i>
        <i
          class="fa fa-solid fa-circle text-yellow"
          v-if="item.final_judgement === 'in sospeso'"
        ></i>
      </span>
    </div>
  </td>
  <td>
    <div class="d-flex px-2 py-1">
      <span class="text-secondary text-xs font-weight-bold">
        <a
          @click.prevent="this.editTube"
          class="text-secondary font-weight-bold cursor-pointer"
          data-toggle="tooltip"
          data-original-title="Modifica"
        >
          <i
            class="fa fa-pen"
            v-if="!this.commessa.closed || this.user.roles !== 'operatore'"
          ></i>
          <i class="fa fa-eye" v-else></i>
        </a>
        {{ item.tube_number }}
      </span>
    </div>
  </td>
  <td>
    <div class="d-flex px-2 py-1">
      <span class="text-secondary text-xs font-weight-bold">
        {{ item.factory_number }}
      </span>
    </div>
  </td>
  <td class="align-middle text-center text-sm">
    <span class="text-secondary text-xs font-weight-bold">
      {{ item.serial_number }}
    </span>
  </td>
  <td class="align-middle">
    <a
      id="excel{{item._id}}"
      href="#"
      @click.prevent="this.exportExcel(item)"
      class="text-secondary font-weight-bold cursor-pointer me-2"
    >
      <i class="fas fa-file-excel"></i>
    </a>
    <tube-delete-btn
      :tube-prop="item"
      :commessa-id="this.commessa._id"
    ></tube-delete-btn>
  </td>
</template>

<script>
import useTubeStore from "@/store/tube.store";
import axios from "axios";
import useCommessaStore from "@/store/commessa.store";
import { getItem } from "@/utility/localStorageControl";
import TubeDeleteBtn from "@/views/tube/tubeDeleteBtn.vue";

export default {
  name: "table-tube-row",
  components: { TubeDeleteBtn },
  props: {
    updateTableTubeRow: {
      type: Function,
    },
    item: {
      type: Object,
      required: false,
    },
  },
  watch: {
    item(newValue) {
      this.tube = newValue;
    },
  },
  data() {
    return {
      tube: {},
      user: {},
      commessa: {},
    };
  },
  async mounted() {
    this.user = getItem("user");
    this.commessa = await useCommessaStore().getCommessa();
  },
  methods: {
    async editTube() {
      this.tube = this.item;
      const response = await axios.get(
          this.$endpoint + "/commessa/tube/pictrureobj/" + this.tube._id,
          {
            headers: {
              authorization: process.env.VUE_APP_APY_KEY,
            },
          }
      );

      this.tube.picture = response.data.picture
      await useTubeStore().setTube(this.tube);
      this.updateTableTubeRow(this.tube, 3);
    },
    async getPdf(tube) {
      if (tube) {
        const response = await axios.get(
          this.$endpoint + "/commessa/pdf/tube/" + tube._id,
          {
            responseType: "blob",
            headers: {
              authorization: process.env.VUE_APP_APY_KEY,
            },
          }
        );
        // Converti l'Array Buffer in un Blob
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        // Crea un URL oggetto dal Blob
        const pdfURL = URL.createObjectURL(pdfBlob);
        // Apri una nuova scheda del browser con l'URL del PDF
        window.open(pdfURL, "_blank");
      }
    },
    async exportExcel(tube) {
      if (tube) {
        const response = await axios.get(
          this.$endpoint + "/commessa/excel/tube/" + tube._id,
          {
            responseType: "blob",
            headers: {
              authorization: process.env.VUE_APP_APY_KEY,
            },
          }
        );

        // Converti l'Array Buffer in un Blob
        const excelBlob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // Crea un URL oggetto dal Blob
        const excelURL = URL.createObjectURL(excelBlob);
        // Crea un elemento <a> e impostalo per il download
        const link = document.createElement("a");
        link.href = excelURL;
        const fileName = `commessa-${this.commessa.number}.xlsx`;
        link.setAttribute("download", fileName);

        // Aggiungi l'elemento <a> al DOM
        document.body.appendChild(link);

        // Clicca sul link per avviare il download
        link.click();

        // Rimuovi l'elemento <a> dal DOM
        document.body.removeChild(link);
      }
    },
  },
};
</script>
