import { createStore } from "vuex";

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: true,
    sidebarType: "bg-white",
    isRTL: false,
    mcolor: "",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default"
  },
  mutations: {
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sidenav_show.classList.remove("g-sidenav-hidden");
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sidenav_show.classList.add("g-sidenav-hidden");
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    }
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    }
  },
  getters: {}
});
