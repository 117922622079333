<script>
import { defineComponent } from "vue";
import DeleteModal from "@/components/deleteModal.vue";
import router from "@/router";
import axios from "axios";
import { getItem } from "@/utility/localStorageControl";
import useTubeStore from "@/store/tube.store";

export default defineComponent({
  name: "tubeDeleteBtn",
  data() {
    return {
      tube: {},
      user: {},
      tubeList: [],
    };
  },
  props: {
    tubeProp: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    commessaId: {
      type: String,
    },
  },
  components: { DeleteModal },
  created() {
    this.user = getItem("user");
  },
  mounted() {
    this.tube = this.tubeProp;
    this.tubeList = useTubeStore().getTubeList();
  },
  methods: {
    openModal(id) {
      this.$vfm.open("deleteModal" + id);
    },
    async closeModal(id) {
      await router.push({ name: "Commessa", params: { id: this.commessaId } });
      await this.$vfm.close("deleteModal" + id);
    },
    async deleteTube(tube) {
      this.$emit("deleteObject", tube);
      const response = await axios.delete(
        this.$endpoint + "/commessa/tube/delete/" + tube._id,
        {
          headers: {
            authorization: process.env.VUE_APP_APY_KEY,
          },
        }
      );

      if (response.data.status === "OK") {
        this.show_success = true;
        this.alert_msg = "Tubo eliminato con successo";
        this.tubeList = useTubeStore().removeTubeToList(this.tube._id);
      }

      await this.closeModal(tube._id);
    },
  },
});
</script>

<template>
  <a
    @click.stop="openModal(this.tube._id)"
    class="text-secondary font-weight-bold text-xs ms-2"
    data-toggle="tooltip"
    data-original-title="Elimina"
    href="javascript:;"
    v-if="this.user.roles === 'superadmin'"
  >
    <i class="fa fa-trash"></i
  ></a>
  <delete-modal
    :modalId="'deleteModal' + this.tube._id"
    :object="this.tube"
    @deleteObject="deleteTube"
    @closeModal="closeModal(this.tube._id)"
  />
</template>
