<script>
import { defineComponent } from "vue";
import useTubeStore from "@/store/tube.store";
import useCommessaStore from "@/store/commessa.store";

export default defineComponent({
  name: "PhotoCamera",
  components: {},
  data() {
    return {
      storeTube: useTubeStore(),
      storeCommessa: useCommessaStore(),
      tubeData: {},
      selectedImageUrl: null,
      showCameraBtn: false,
      mediaStream: null,
      facingMode: "user",
      isLoading: false,
    };
  },
  props: {
    tube: {
      type: Object,
    },
  },
  watch: {
    async tube(newValue) {
      this.tubeData = await this.storeTube.getTube();
      this.selectedImageUrl = null;
      this.isLoading = true;
      if ("picture" in this.tubeData) {
        if (this.isObject(this.tubeData.picture)) {
          if ("buffer" in this.tubeData.picture) {
            if (this.isObject(this.tubeData.picture.buffer)) {
              if ("data" in this.tubeData.picture.buffer) {
                this.selectedImageUrl =
                  "data:" +
                  this.tubeData.picture.mimetype +
                  ";base64," +
                  this.tubeData.picture?.buffer?.data;
              }
            } else {
              this.selectedImageUrl =
                "data:" +
                this.tubeData.picture.mimetype +
                ";base64," +
                this.tubeData.picture?.buffer;
            }
          } else {
            this.selectedImageUrl = null;
          }
        } else {
          this.selectedImageUrl = this.tubeData.picture;
        }
      }
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Simula un ritardo
      this.isLoading = false;
    },
  },
  mounted() {
    this.tubeData = this.tube;
  },
  methods: {
    catturaFoto() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;

      if (!video || !canvas) return;

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);

      // Converti l'immagine del canvas in Data URL
      this.tubeData.picture = canvas.toDataURL("image/jpeg");
      this.selectedImageUrl = this.tubeData.picture;
    },
    processFile(event) {
      const file = event.target.files[0];
      if (file) {
        this.convert(file)
          .then((selectedImageUrl) => {
            this.tubeData.picture = selectedImageUrl;
          })
          .catch((error) => {
            console.error("Errore durante la conversione del file:", error);
          });
      }
    },
    convert(myFile) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        if (fileReader && myFile) {
          fileReader.onload = (e) => {
            this.selectedImageUrl = e.target.result;
            resolve(this.selectedImageUrl); // Risolvi la Promise con il Data URL una volta che è disponibile.
          };
          fileReader.onerror = reject; // Rifiuta la Promise in caso di errore.
          fileReader.readAsDataURL(myFile);
        } else {
          reject("No FileReader support or file not provided");
        }
      });
    },
    isObject(prop) {
      return typeof prop === "object" && prop !== null && !Array.isArray(prop);
    },
    async apriCamera() {
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          // .getUserMedia({ video: true })
          .getUserMedia({ video: { facingMode: this.facingMode } })
          .then((stream) => {
            this.$nextTick(() => {
              this.$refs.video.srcObject = stream;
              this.showCameraBtn = true;
            });
          })
          .catch((error) => {
            console.error(
              "Non è stato possibile accedere alla videocamera:",
              error.message
            );
          });
      }
    },
    async cambiaFotocamera() {
      // Alterna tra fotocamera frontale e posteriore
      this.facingMode = this.facingMode === "user" ? "environment" : "user";
      this.chiudiCamera();
      await this.apriCamera();
    },
    chiudiCamera() {
      const videoElement = this.$refs.video;
      const stream = videoElement.srcObject;

      if (stream) {
        // Interrompi lo streaming video
        stream.getTracks().forEach((track) => {
          track.stop();
          this.showCameraBtn = false;
        });

        // Resetta l'oggetto src del tag video
        videoElement.srcObject = null;
      }
    },
  },
});
</script>

<template>
  <div>
    <button @click.prevent="this.apriCamera" class="btn btn-primary">
      Apri fotocamera
    </button>
    <button
      v-if="this.showCameraBtn"
      @click.prevent="this.cambiaFotocamera"
      class="btn btn-outline-primary ms-2"
    >
      Cambia fotocamera
    </button>
    <button @click.prevent="this.chiudiCamera" class="btn btn-primary ms-2">
      Chiudi fotocamera
    </button>
    <input
      class="btn btn-primary ms-2"
      type="file"
      accept="image/x-png,image/jpeg"
      @change="this.processFile"
    />
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div v-if="this.isLoading" class="loader"></div>
        <img
          class="w-100 h-auto"
          v-if="this.selectedImageUrl && !this.isLoading"
          :src="this.selectedImageUrl"
          alt="Anteprima Immagine"
        />
      </div>
      <div class="col-md-12 col-sm-12 mt-2">
        <canvas
          ref="canvas"
          style="display: none"
          width="300"
          height="200"
        ></canvas>
        <video ref="video" autoplay width="300" height="200"></video>
      </div>
      <div class="col-md-12 col-sm-12 mt-2">
        <button
          v-if="this.showCameraBtn"
          @click.prevent="this.catturaFoto"
          class="btn btn-primary"
        >
          Scatta Foto
        </button>
      </div>
    </div>
  </div>
</template>

<style>
.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
