<template>
  <router-link to="/" class="btn mb-0 btn-sm btn-outline-primary text-sm">
    <span>
      <i class="ni ni-tv-2 text-primary opacity-10"></i>
      Torna alla dashboard
    </span>
  </router-link>
</template>

<script>
export default {
  name: "dashboard-link",
};
</script>
