<template>
  <div class="py-4 container-fluid">
    <div class="card shadow-lg mt-n4">
      <div class="card-body p-3">
        <div class="row gx-4">
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">Commesse</h5>
            </div>
          </div>
          <div
            class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
          >
            <div class="nav-wrapper position-relative end-0">
              <ul
                class="p-1 bg-transparent nav nav-pills nav-fill d-flex flex-nowrap justify-content-around"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="btn mb-0 btn-sm btn-outline-info text-sm me-2"
                    data-bs-toggle="tab"
                    href="javascript:;"
                    role="tab"
                    aria-selected="true"
                    @click="addCommessa()"
                    v-if="this.user.roles !== 'operatore'"
                    ><i class="fa fa-plus"></i>
                    <span class="ms-1">Aggiungi commessa</span>
                  </a>
                </li>
                <li class="nav-item">
                  <dashboard-link></dashboard-link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <vee-form @submit="this.searchCommesse">
          <div class="row">
            <div class="col-12 mb-2 form-group">
              <label for="search_client_name" class="form-control-label"
                >Cliente</label
              >
              <vee-field
                type="text"
                name="search_client_name"
                class="form-control"
                v-model="this.commessa_search.client_name"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-4 form-group">
              <label for="search_number" class="form-control-label"
                >Numero</label
              >
              <vee-field
                type="text"
                name="search_number"
                class="form-control"
                v-model="this.commessa_search.number"
              />
            </div>
            <div class="col-4 form-group">
              <label for="search_created_at" class="form-control-label"
                >Data</label
              >
              <vee-field
                type="date"
                name="search_created_at"
                class="form-control"
                v-model="this.commessa_search.createdAt"
              />
            </div>
            <div class="col-4 form-group">
              <label for="search_state" class="form-control-label">Stato</label>
              <select
                class="form-select"
                name="search_state"
                id="search_state"
                v-model="this.commessa_search.state"
                @change="this.searchCommesse"
              >
                <option selected value="">Tutte</option>
                <option value="open">Aperte</option>
                <option value="close">Chiuse</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <argon-button
                size="sm"
                class="float-start mt-2"
                variant="gradient"
                color="primary"
                >Cerca
              </argon-button>
            </div>
          </div>
        </vee-form>
      </div>
    </div>
  </div>

  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <commesse-table :commesse="this.commesse" v-if="this.commesse" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import CommesseTable from "@/views/commessa/commessaTable.vue";
import DashboardLink from "@/views/dashboard/DashboardLink.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { CommessaSearchModel } from "@/models/commessa/commessa_search.model";
import {getItem} from "@/utility/localStorageControl";

export default {
  name: "commesse-list",
  components: {
    ArgonButton,
    DashboardLink,
    CommesseTable,
  },
  data() {
    return {
      commesse: null,
      commessa_search: new CommessaSearchModel(),
      user:{}
    };
  },
  mounted() {
    this.user = getItem("user");
    this.searchCommesse();
  },
  methods: {
    addCommessa() {
      router.push({ name: "Nuova commessa" });
    },
    async searchCommesse() {
      let loader;
      try {
        loader = this.$loading.show({
          loader: "dots",
          color: "#2dce89",
        });
        const response = await axios.post(
          this.$endpoint + "/commessa/search",
          {
            search: this.commessa_search,
          },
          {
            headers: {
              authorization: process.env.VUE_APP_APY_KEY,
            },
          }
        );
        this.commesse = response.data.commesse;

        loader.hide();
      } catch (ex) {
        console.log(ex.message);
      }
    },
  },
};
</script>

<style>
ul li input[type="text"] {
  display: block;
}
</style>
