<template>
  <div class="col-auto">
    <div class="h-100">
      <h5 class="mb-1" v-if="this.id">{{ this.editTitle }}</h5>
      <h5 class="mb-1" v-else>{{ this.title }}</h5>
    </div>
  </div>
</template>
<script>
export default {
  name: "header-title",
  props: {
    id: {
      type: String,
      required: false,
    },
    title: String,
    editTitle: {
      type: String,
      required: false,
    },
  },
};
</script>
