<template>
  <div class="col-md-3">
    <div class="form-check form-switch p-4">
      {{ visual.description }}
    </div>
  </div>
  <div class="col-md-3">
    <label :for="'judgment_' + visual.slug" class="form-control-label"
      >Giudizio</label
    >
    <select
      class="form-select"
      aria-label="Default select example"
      :name="'judgment_' + visual.slug"
      v-model="selectedValues[visual.slug]"
    >
      <option selected value="">Seleziona un giudizio</option>
      <option
        v-for="judgment in judgments"
        :key="judgment._id"
        :value="judgment.description"
      >
        {{ judgment.description }}
      </option>
    </select>
  </div>
  <div class="col-md-3">
    <label :for="'note_' + visual.slug" class="form-control-label">Note</label>
    <vee-field
      type="text"
      class="form-control"
      :name="'note_' + visual.slug"
      :id="'note_' + visual.slug"
      v-model="noteValues[visual.slug]"
    />
  </div>
  <div class="col-md-3">
    <div class="form-check form-switch p-4">
      <vee-field
        class="form-check-input m-3"
        type="checkbox"
        :name="'result_' + visual.slug"
        :id="'result_' + visual.slug"
        :checked="resultValues[visual.slug]"
        v-model="resultValues[visual.slug]"
        :value="true"
        :unchecked-value="false"
      />
      <label class="form-check-label" :for="'result_' + visual.slug">
        Esito
      </label>
    </div>
  </div>
</template>
<script>
export default {
  name: "tube-visual-form-row",
  watch: {
    notes(newValue) {
      this.noteValues = newValue;
    },
    selValues(newValue) {
      this.selectedValues = newValue;
    },
    results(newValue) {
      this.resultValues = newValue;
    },
  },
  data() {
    return {
      selectedValues: {},
      noteValues: {},
      resultValues: {},
      visual: {},
    };
  },
  props: {
    visualProp: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    judgments: {
      type: Object,
      required: false,
    },
    selValues: {
      type: Object,
      required: false,
    },
    notes: {
      type: Object,
      required: false,
    },
    results: {
      type: Object,
      required: false,
    },
  },
  async created() {
    this.visual = this.visualProp;
    this.selectedValues = this.selValues;
    this.noteValues = this.notes;
    this.resultValues = this.results;
  },
  methods: {
    changeJudgment() {

      if (
          this.selectedValues[this.visual.slug].lower === "non conforme"
      ) {
        console.log(this.selectedValues[this.visual.slug].lower);
      }
      //   this.errorFinalJudgement = true;
      //   this.tube.result_final = false;
      // } else {
      //   this.errorFinalJudgement = false;
      // }
      //
      // if (this.tube.result_final && this.tube.hydraulic_result === "negativo") {
      //   this.tube.final_judgement = "non conforme";
      //   this.showOptionsFinalJudgement = false;
      // } else {
      //   this.showOptionsFinalJudgement = true;
      // }
    }
  }
};
</script>
