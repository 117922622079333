import { defineStore } from 'pinia';
import { TubeModel } from '@/models/tube/tube.model';

export default defineStore("tube", {
  state: () => ({
    tube: {},
    tube_list: [TubeModel]
  }),
  actions: {
    async setTubeList(tubes: any){
      this.tube_list = tubes;
    },
    async getTubeList(){
      return this.tube_list;
    },
    addTubeToList: async function(tube: any):Promise<any> {
      this.tube_list = this.tube_list.map((obj: any) =>
        obj._id === tube._id ? tube : obj,
      );
      // Controllo se l'oggetto con lo stesso _id è già presente nell'array
      const objectFound = this.tube_list.some((obj: any) => obj._id === tube._id);

      // Se l'oggetto NON è stato trovato, lo aggiungiamo all'array
      if (!objectFound) {
        this.tube_list.push(tube);
      }

      return this.tube_list;
    },
    async clearTubeList(){
      this.tube_list=[TubeModel];
      return this.tube_list;
    },
    async setTube(tube: any){
      this.tube = tube;
    },
    async getTube(){
      return this.tube;
    },
    async removeTube(){
      this.tube={};
    },
    removeTubeToList: async function(tubeId: string):Promise<any> {
      this.tube_list = this.tube_list.filter((obj: any) => obj._id !== tubeId);

      return this.tube_list;
    },
  }
});
