<template>
  <div class="card">
    <div class="card-body px-0 pt-0 pb-2">
      <argon-alert color="success" v-if="this.show_success" class="mx-2 mt-2"
        >{{ alert_msg }}
      </argon-alert>
      <div class="table-responsive p-0" v-if="commesseList">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th></th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Numero
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Cliente
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Data creazione
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="commessa in this.commesseList" :key="commessa._id">
              <td>
                <div class="d-flex px-2 py-1">
                  <span class="text-secondary text-xs font-weight-bold">
                    <i class="fa fa-solid fa-lock" v-if="commessa.closed"></i>
                    <i class="fa fa-solid fa-lock-open text-primary" v-else></i>
                  </span>
                </div>
              </td>
              <td>
                <div class="d-flex px-2 py-1">
                  <a
                    @click="editCommesse(commessa._id)"
                    class="text-secondary font-weight-bold text-xs me-2"
                    data-toggle="tooltip"
                    data-original-title="Modifica"
                    href="javascript:;"
                  >
                    <i class="fa fa-pen"></i
                  ></a>
                  <span class="text-secondary text-xs font-weight-bold">
                    {{ commessa.number }}
                  </span>
                </div>
              </td>
              <td>
                <div class="d-flex px-2 py-1">
                  <span class="text-secondary text-xs font-weight-bold">
                    {{ commessa.client_name }}
                  </span>
                </div>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">
                  {{ getFormattedDate(commessa.createdAt) }}</span
                >
              </td>
              <td class="align-middle">
                <commessa-delete-btn
                  :commessa-prop="commessa"
                  :commesse="this.commesseList"
                  :url="'Lista commesse'"
                ></commessa-delete-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import ArgonAlert from "@/components/ArgonAlert.vue";
import moment from "moment/moment";
import CommessaDeleteBtn from "@/views/commessa/commessaDeleteBtn.vue";

export default {
  name: "commesse-table",
  props: {
    commesse: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      show_success: false,
      alert_msg: "",
      commesseList: {},
    };
  },
  components: { CommessaDeleteBtn, ArgonAlert },
  watch: {
    commesse(newValue) {
      this.commesseList = newValue;
    },
  },
  mounted() {
    this.commesseList = this.commesse;
  },
  methods: {
    editCommesse(commessaId) {
      router.push({ name: "Commessa", params: { id: commessaId } });
    },
    getFormattedDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
};
</script>
