<!-- ConnectionStatus.vue -->
<template>
  <div class="">
    <i v-if="networkStatus === 'Online'" class="fa fa-wifi text-white"></i>
    <i v-if="networkStatus === 'Offline'" class="fa fa-unlink text-white"></i>
  </div>
</template>

<script>
export default {
  name: 'NetworkStatus',
  data() {
    return {
      networkStatus: navigator.onLine ? 'Online' : 'Offline',
    };
  },
  methods: {
    updateNetworkStatus() {
      this.networkStatus = navigator.onLine ? 'Online' : 'Offline';
    }
  },
  mounted() {
    window.addEventListener('online', this.updateNetworkStatus);
    window.addEventListener('offline', this.updateNetworkStatus);
  },
  beforeUnmount() {
    window.removeEventListener('online', this.updateNetworkStatus);
    window.removeEventListener('offline', this.updateNetworkStatus);
  }
}
</script>
