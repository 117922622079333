<template>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName" textWhite="text-white" />

      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        id="navbar"
      >
        <div class="pe-md-3 d-flex align-items-center ms-md-auto"></div>
        <div class="me-1"><network-status></network-status></div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center me-2" v-if="this.user">
            <span class="d-sm-inline d-none font-weight-bold text-white" >{{ this.user.name }} {{ this.user.surname }}</span>
          </li>
          <li class="nav-item d-flex align-items-center">
            <a
              @click.prevent="signOutAction"
              class="px-0 nav-link font-weight-bold text-white"
              href="#"
            >
              <i class="fa fa-user me-sm-2"></i>
              <span class="d-sm-inline d-none">Logout</span></a
            >
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="#"
              @click="toggleSidebar"
              class="p-0 nav-link text-white"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapActions } from "pinia";
import Breadcrumbs from "./Breadcrumbs.vue";
import { mapMutations } from "vuex";
import router from "@/router";
import { useUserStore } from "@/store/user";
import {getItem} from "@/utility/localStorageControl";
import NetworkStatus from '@/components/Online/Online.vue';

export default {
  name: "app-navbar",
  data() {
    return {
      showMenu: false,
      user :{}
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
    this.user = getItem('user');
  },
  methods: {
    ...mapMutations(["navbarMinimize"]),
    ...mapActions(useUserStore, ["signOut"]),
    async signOutAction() {
      await this.signOut();
      /// await localStorage.removeItem('user');
      //await localStorage.removeItem('token');
      await router.push("/login");
    },

    toggleSidebar() {
      // this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
  },
  components: {
    NetworkStatus,
    Breadcrumbs,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
