<template>
  <router-link :to="this.url" class="mb-0 btn btn-outline-warning text-sm">
    <span> <i class="fas fa-chevron-left me-1"></i>Torna alla lista </span>
  </router-link>
</template>

<script>
export default {
  name: "back-to-list",
  props: {
    url: String,
  },
};
</script>
