<script>
export default {
  name: 'select-autocomplete',
  data() {
    return {
      filteredList: [],
    };
  },
  props: {
    inputModel: {
      type: String,
      default: () => '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: () => '',
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    fieldName: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    checkValue(valore) {
      if (valore.length >2) {
        this.filteredList = [];
        this.filteredList = this.list.filter((item) =>
          item.description.toLowerCase().includes(valore.toLowerCase()),
        );
      } else {
        this.filteredList = [];
      }
      this.$emit('update:inputModel', valore);
    },
    selectValue(value) {
      this.model = value;
      this.filteredList = [];
      this.$emit('update:inputModel', value);
    },
    handleBlur() {
      setTimeout(() => {
        this.filteredList = []; // Chiude la lista dopo un breve ritardo
      }, 300); // 100 millisecondi tendono ad essere sufficienti
    },
  },
};
</script>

<template>
  <label :for="this.fieldName" class="form-control-label">
    {{ this.label }}
  </label>

  <vee-field
    type="text"
    class="form-control"
    :name="this.fieldName"
    :placeholder="this.placeholder"
    :modelValue="inputModel"
    @input="checkValue($event.target.value)"
    autocomplete="off"
    @blur="handleBlur"
  />

  <ul v-if="this.filteredList.length > 0" class="options-list">
    <li
      v-for="item in this.filteredList"
      :key="item._id"
      @click.prevent="this.selectValue(item.description)"
    >
      {{ item.description }}
    </li>
  </ul>
</template>

<style>
.options-list {
  width: 100%;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d2d6da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.5rem;
  transition: box-shadow 0.15s ease, border-color 0.15s ease;
}

.options-list li {
  cursor: pointer;
  background-color: #fff;
  list-style: none;
  margin: 0.5rem;
}
</style>
