<template>
  <div v-if="total > pagination_size" class="col-12">
    <vue-awesome-paginate
      :total-items="total"
      :items-per-page="pagination_size"
      :max-pages-shown="10"
      v-model="currentPage"
      :on-click="onClickHandler"
    />
  </div>
</template>

<script>
import { VueAwesomePaginate } from "vue-awesome-paginate";

export default {
  name: "app-pagination",
  components: { VueAwesomePaginate },
  data() {
    return {
      currentPage: 1,
      strUrl: "",
    };
  },
  props: {
    orders: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    pages: { type: Number },
    total: { type: Number },
    pagination_size: { type: Number },
    color: String,
    size: String,
  },
  methods: {
    getClasses: (color, size) => {
      let colorValue, sizeValue;

      colorValue = color ? `pagination-${color}` : null;
      sizeValue = size ? `pagination-${size}` : null;

      return `${colorValue} ${sizeValue}`;
    },
    onClickHandler(page) {
      console.log(page);
      this.$emit('getPaginationItems', {'page': page});
    },
  },
};
</script>

<style>
.pagination-container {
  display: flex;
  column-gap: 10px;
}
.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}
.paginate-buttons:hover {
  background-color: #d8d8d8;
}
.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.active-page:hover {
  background-color: #2988c8;
}
</style>
