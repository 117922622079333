<template>
  <router-link :to="route" class="nav-link">
    <div class="mb-4 card">
      <div class="p-3 card-body">
        <div class="d-flex" :class="directionReverse ? reverseDirection : ''">
          <div>
            <div
              class="text-center icon icon-shape"
              :class="`${iconBackground} border-radius-2xl`"
            >
              <i
                class="text-lg opacity-10"
                :class="iconClass"
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div :class="contentClass">
            <div class="numbers">
              <p
                class="mb-0 text-sm text-uppercase font-weight-bold"
                :class="titleColor"
              >
                {{ title }}
              </p>
              <h5 class="font-weight-bolder" :class="valueColor">
                {{ value }}
              </h5>
              <span class="text-sm" :class="percentageColor">{{
                percentage
              }}</span>
              <span class="text-warning">{{ detail }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "app-card",
  data() {
    return {
      reverseDirection: "flex-row-reverse justify-content-between",
    };
  },
  props: {
    route: {
      type: String,
      default: "/",
    },
    directionReverse: Boolean,
    title: {
      type: String,
      required: true,
    },
    detail: {
      type: String,
      required: true,
    },
    titleColor: {
      type: String,
    },
    value: {
      required: true,
    },
    valueColor: {
      type: String,
    },
    percentage: String,
    iconClass: {
      type: String,
      required: true,
    },
    percentageColor: {
      type: String,
      default: "text-success",
    },
    iconBackground: {
      type: String,
      default: "bg-white",
    },
    contentClass: {
      type: String,
    },
  },
  methods: {
    // goToRoute(){
    //   console.log(this.route);
    // }
  },
};
</script>
