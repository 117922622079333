import { defineStore } from "pinia";

export default defineStore("commessa", {
  state: () => ({
    commessa: {},
    commessa_list: [],
  }),
  actions: {
    async setCommessaList(commesse: any) {
      this.commessa_list = commesse;
    },
    async setCommessa(commessa: any) {
      this.commessa = commessa;
    },
    async getCommessa() {
      return this.commessa;
    },
  },
});
