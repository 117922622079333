<template>
  <td>
    <div class="d-flex px-2 py-1">
      <span class="text-secondary text-xs font-weight-bold">
        {{ user.name }}
      </span>
    </div>
  </td>
  <td>
    <div class="d-flex px-2 py-1">
      <span class="text-secondary text-xs font-weight-bold">
        {{ user.surname }}
      </span>
    </div>
  </td>
  <td>
    <div class="d-flex px-2 py-1">
      <span class="text-secondary text-xs font-weight-bold">
        {{ user.email }}
      </span>
    </div>
  </td>
  <td class="align-middle text-center text-sm">
    <span v-if="user.enabled" class="badge badge-sm bg-gradient-success"
      >Abilitato</span
    >
    <span v-else class="badge badge-sm bg-gradient-danger">Disabilitato</span>
  </td>
  <td class="align-middle text-center">
    <span class="text-secondary text-xs font-weight-bold">
      {{ getFormattedDate(user.createdAt) }}</span
    >
  </td>

</template>

<script>
import moment from "moment";

export default {
  name: "table-row-user",
  props: {
    user: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  methods:{
    getFormattedDate(date) {
      return moment(date).format("DD-MM-YYYY")
    },
  }
};
</script>
