<template>
  <div class="container-fluid">
    <div class="card shadow-lg mt-3">
      <div class="card-body p-3">
        <div class="row gx-4">
          <header-title
            :id="this.idUser"
            :title="'Nuovo utente'"
            :edit-title="'Modifica utente ' + this.user.email"
          ></header-title>
          <div
            class="mx-auto mt-3 col-lg-3 col-sm-3 my-sm-auto ms-sm-auto me-sm-0"
          >
            <div class="nav-wrapper position-relative end-0">
              <ul
                class="p-1 bg-transparent nav nav-pills nav-fill"
                role="tablist"
              >
                <li class="nav-item">
                  <back-to-list :url="'/user-list'"></back-to-list>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <vee-form @submit="this.saveUser" :validation-schema="userSchema">
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <argon-alert color="danger" v-if="show_alert"
                >{{ alert_msg }}
              </argon-alert>
              <argon-alert color="success" v-if="show_success"
                >{{ alert_msg }}
              </argon-alert>
              <div class="row">
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >Nome</label
                  >
                  <vee-field
                    type="text"
                    class="form-control"
                    name="name"
                    id="name"
                    v-model="this.user.name"
                  />
                  <error-message class="text-danger text-xs" name="name" />
                </div>
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >Cognome</label
                  >
                  <vee-field
                    type="text"
                    class="form-control"
                    name="surname"
                    id="surname"
                    v-model="this.user.surname"
                  />
                  <error-message class="text-danger text-xs" name="surname" />
                </div>
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >Email</label
                  >
                  <vee-field
                    type="email"
                    class="form-control"
                    name="email"
                    id="email"
                    v-model="this.user.email"
                  />
                  <error-message class="text-danger text-xs" name="email" />
                </div>
                <div
                  class="col-md-6"
                  v-if="!this.idUser || this.changePassword"
                >
                  <label for="example-text-input" class="form-control-label"
                    >Password</label
                  >
                  <vee-field
                    type="password"
                    class="form-control"
                    name="password"
                    id="password"
                    v-model="this.user.password"
                  />
                  <error-message class="text-danger text-xs" name="password" />
                </div>
                <div
                  class="col-md-6"
                  v-if="!this.idUser || this.changePassword"
                >
                  <label for="example-text-input" class="form-control-label"
                    >Ripeti password</label
                  >
                  <vee-field
                    type="password"
                    class="form-control"
                    name="password2"
                    id="password2"
                  />
                  <error-message class="text-danger text-xs" name="password2" />
                </div>

                <div class="col-md-12">
                  <label for="example-text-input" class="form-control-label"
                    >Ruolo</label
                  >
                  <vee-field
                    as="select"
                    class="form-select"
                    name="role"
                    id="role"
                    v-model="this.user.roles"
                  >
                    <option selected value="">Seleziona il ruolo</option>
                    <option value="superadmin">Superadmin</option>
                    <option value="ufficio">Ufficio</option>
                    <option value="operatore">Operatore</option>
                  </vee-field>
                </div>
                <div class="col-md-12">
                  <div class="form-check form-switch mt-2">
                    <vee-field
                      class="form-check-input"
                      type="checkbox"
                      id="enabled"
                      name="enabled"
                      :checked="this.user.enabled"
                      v-model="this.user.enabled"
                      :value="true"
                      :unchecked-value="false"
                    />

                    <label class="form-check-label" :for="this.user.enabled">
                      Abilita
                    </label>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div>
                  <argon-button
                    size="sm"
                    class="mt-4"
                    variant="gradient"
                    color="success"
                    >Salva
                  </argon-button>
                  <argon-button
                    type="button"
                    class="btn btn-sm btn-dark mt-4 mb-0 ms-2"
                    @click="this.changePasswordAction"
                    v-if="this.idUser"
                  >
                    Cambio password
                  </argon-button>
                </div>
                <router-link
                  to="/user-list"
                  class="btn btn-sm btn-dark float-right mt-4 mb-0"
                >
                  Annulla
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </vee-form>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import axios from "axios";
import ArgonAlert from "@/components/ArgonAlert.vue";
import router from "@/router";
import HeaderTitle from "@/components/Header/HeaderTitle.vue";
import BackToList from "@/components/Header/BackToList.vue";
import { UserModel } from "@/models/user/user.model";
import { ErrorMessage } from "vee-validate";

export default {
  name: "edit-user",
  data() {
    return {
      userSchema: {
        name: "required",
        surname: "required",
        email: "required|email",
        password: "required|min:8|uppercaseSymbol",
        password2: "required|passwords_mismatch:password",
      },
      show_alert: false,
      show_success: false,
      alert_msg: "",
      user: new UserModel(),
      idUser: "",
      changePassword: false,
    };
  },
  beforeMount() {
    this.idUser = this.$route.params.id;
  },
  async mounted() {
    await this.getUser();
    if (!this.idUser) {
      this.user.roles = "";
    } else {
      this.userSchema = {
        name: "required",
        surname: "required",
        email: "required|email",
      };
    }
  },
  components: {
    ErrorMessage,
    BackToList,
    HeaderTitle,
    ArgonAlert,
    ArgonButton,
  },
  methods: {
    router() {
      return router;
    },
    async getUser() {
      try {
        if (this.idUser) {
          const response = await axios.get(
            this.$endpoint + "/user/show/" + this.idUser,
            {
              headers: {
                authorization: process.env.VUE_APP_APY_KEY,
              },
            }
          );
          if (response.data.status === "OK") {
            this.user = response.data.data;
            this.user.password = "";
          }
        }
      } catch (e) {
        console.log(e.toString());
      }
    },
    async saveUser() {
      try {
        const formData = new FormData();
        formData.append("user", JSON.stringify(this.user));
        formData.append("changepassword", this.changePassword);

        const response = await axios.post(
          this.$endpoint + "/user/add",
          formData,
          {
            params: { id: this.idUser },
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: process.env.VUE_APP_APY_KEY,
            },
          }
        );

        if (response.data.status === "OK") {
          this.show_success = true;
          this.alert_msg = "Utente salvato con successo";
        } else {
          this.show_alert = true;
          this.alert_msg = response.data.message;
        }
      } catch (e) {
        this.show_alert = true;
        this.alert_msg = "Errore nel salvataggio";
      }
    },
    changePasswordAction() {
      this.changePassword = !this.changePassword;

      if (this.changePassword) {
        this.userSchema = {
          name: "required",
          surname: "required",
          email: "required|email",
          password: "required|min:8|uppercaseSymbol",
          password2: "required|passwords_mismatch:password",
        };
      } else {
        this.userSchema = {
          name: "required",
          surname: "required",
          email: "required|email",
        };
      }
    },
  },
};
</script>
