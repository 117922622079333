import { createApp } from "vue";
import { createVfm } from "vue-final-modal";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./registerServiceWorker";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";

import ArgonDashboard from "./argon-dashboard";
import { createPinia } from "pinia";
import VeeValidatePlugin from "./utility/validation";
import "vue-final-modal/style.css";
//loader
import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
//paginazione
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";

import "./assets/js/bootstrap.esm.min.js";
import "./assets/css/accordion.css";

import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import { setLocale } from '@vee-validate/i18n';

import "./assets/css/fluortecno.css";

const pinia = createPinia();
const vfm = createVfm();

const app = createApp(App);

app.use(pinia).use(store).use(ArgonDashboard).use(router).use(vfm);

app.config.globalProperties.$endpoint = process.env.VUE_APP_END_POINT_URL;
app.use(VeeValidatePlugin);
app.use(LoadingPlugin);
app.use(VueAwesomePaginate);
app.use(ToastPlugin);

setLocale('it');
app.mount("#app");
