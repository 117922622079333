<script>
import { defineComponent } from "vue";
import DeleteModal from "@/components/deleteModal.vue";
import router from "@/router";
import axios from "axios";
import { getItem } from "@/utility/localStorageControl";

export default defineComponent({
  name: "commessaDeleteBtn",
  data() {
    return {
      commessa: {},
      user: {},
      commesseList: {},
    };
  },
  props: {
    commessaProp: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    commesse: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    url: {
      type: String,
    },
  },
  components: { DeleteModal },
  created() {
    this.user = getItem("user");
  },
  mounted() {
    this.commessa = this.commessaProp;
    this.commesseList = this.commesse;
  },
  methods: {
    openModal(id) {
      this.$vfm.open("deleteModal" + id);
    },
    async closeModal(id) {
      await router.push({ name: this.url });
      await this.$vfm.close("deleteModal" + id);
    },
    async deleteCommessa(commessa) {
      this.$emit("deleteObject", commessa);
      const response = await axios.delete(
        this.$endpoint + "/commessa/delete/" + commessa._id,
        {
          headers: {
            authorization: process.env.VUE_APP_APY_KEY,
          },
        }
      );

      if (response.data.status === "OK") {
        this.show_success = true;
        this.alert_msg = "Commessa eliminata con successo";
        const index = this.commesseList.findIndex(
          (obj) => obj._id === commessa._id
        ); // find the post index
        if (~index)
          // if the post exists in array
          this.commesseList.splice(index, 1); //delete the post
      }

      await this.closeModal(commessa._id);
    },
  },
});
</script>

<template>
  <a
    @click.stop="openModal(this.commessa._id)"
    class="text-secondary font-weight-bold text-xs ms-2"
    data-toggle="tooltip"
    data-original-title="Elimina"
    href="javascript:;"
    v-if="this.user.roles === 'superadmin'"
  >
    <i class="fa fa-trash"></i
  ></a>
  <delete-modal
    :modalId="'deleteModal' + this.commessa._id"
    :object="this.commessa"
    @deleteObject="deleteCommessa"
    @closeModal="closeModal(this.commessa._id)"
  />
</template>
