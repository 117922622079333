import { defineStore } from "pinia";
import { getItem, removeItem, setItem } from "@/utility/localStorageControl";

export const useUserStore = defineStore("user", {
  state: () => ({
    userLoggedIn: false,
  }),
  actions: {
    async authenticate(user: any) {
      setItem("user", user);
      this.userLoggedIn = true;
    },
    async signOut() {
      const user = getItem("user");
      if (user) {
        removeItem("user");
      }
      this.userLoggedIn = false;
    },
    async autoRegister() {
      const user = getItem("user");
      if (user) {
        this.userLoggedIn = true;
      }
    },
  },
});
