export class TubeModel {
  _id?: string | undefined;
  commesssaId?: string = "";
  factory_number?: string = "";
  serial_number?: string = "";
  type?: string = "";
  dp?: string = "";
  lg?: string = "";
  color?: string = "";
  fitting_right?: string = "";
  fitting_left?: string = "";
  measure_right?: string = "";
  measure_left?: string = "";
  material_right?: string = "";
  material_left?: string = "";
  area?: string = "";
  substance?: string = "";
  hydraulics?: object;
  hydraulic_result?: string = "";
  visuals?: object;
  components?: object;
  electricals?: object;
  note?: string = "";
  picture?: object | undefined;
  project_pressure?: string = "";
  test_pressure?: string = "";
  result_final?: boolean = false;
  userId?: string = "";
  final_judgement?: string = "";
}
