<template>
  <div class="col-md-4">
    {{ electrical.description }}
  </div>
  <div class="col-md-8">
    <select
      class="form-select"
      :name="'test_' + electrical.slug"
      :id="'test_' + electrical.slug"
      v-model="testValues[electrical.slug]"
      @change.prevent="this.updateValues"
    >
      <option selected value="">Seleziona</option>
      <option value="conduce" v-if="electrical.slug !== 'spark-test'">
        Conduce
      </option>
      <option value="non conduce" v-if="electrical.slug !== 'spark-test'">Non conduce</option>
      <option value="non applicato" v-if="electrical.slug === 'spark-test'">
        Non applicato
      </option>
      <option value="positivo" v-if="electrical.slug === 'spark-test'">
        Positivo
      </option>
      <option value="negativo" v-if="electrical.slug === 'spark-test'">
        Negativo
      </option>
    </select>
  </div>
</template>
<script>
export default {
  name: "tube-electrical-form-row",
  watch: {
    tests(newValue) {
      this.testValues = newValue;
    },
  },
  data() {
    return {
      electrical: {},
      testValues: {},
    };
  },
  props: {
    electricalProp: {
      type: Object,
      required: false,
    },
    tests: {
      type: Object,
      required: false,
    },
  },
  async created() {
    this.electrical = this.electricalProp;
    this.testValues = this.tests;
  },
  methods: {
    updateValues() {
      this.$emit("update:tests", this.testValues);
    },
  },
};
</script>
