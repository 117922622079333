<template>
  <div class="col-md-4">
    {{ component.description }}
  </div>
  <div class="col-md-4">
    <select
      class="form-select"
      :name="'change_' + component.slug"
      :id="'change_' + component.slug"
      v-model="selectedChangeValues[component.slug]"
    >
      <option selected value="">Seleziona</option>
      <option value="si">Si</option>
      <option value="no">No</option>
      <option value="altro">Altro</option>
    </select>
  </div>
  <div class="col-md-4">
    <vee-field
      type="text"
      class="form-control"
      :name="'note_' + component.slug"
      :id="'note_' + component.slug"
      v-model="noteValues[component.slug]"
      :placeholder="'Nota ' + component.description"
    />
  </div>
</template>
<script>
export default {
  name: "tube-component-form-row",
  watch: {
    notes(newValue) {
      this.noteValues = newValue;
    },
    selChange(newValue) {
      this.selectedChangeValues = newValue;
    },
  },
  data() {
    return {
      component: {},
      noteValues: {},
      selectedChangeValues: {},
    };
  },
  props: {
    componentProp: {
      type: Object,
      required: false,
    },
    notes: {
      type: Object,
      required: false,
    },
    selChange: {
      type: Object,
      required: false,
    },
  },
  async created() {
    this.component = this.componentProp;
    this.noteValues = this.notes;
    this.selectedChangeValues = this.selChange;
  },
};
</script>
