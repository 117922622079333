<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          url="/dashboard"
          :class="getRoute() === 'dashboard' ? 'active' : ''"
          :navText="'Dashboard'"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6 ms-2"
        >
          COMMESSE
        </h6>
      </li>
      <li class="nav-item" v-if="this.user?.roles !== 'operatore'">
        <sidenav-item
          url="/commessa-add"
          :class="getRoute() === 'commessa-add' ? 'active' : ''"
          :navText="'Nuova commessa'"
        >
          <template v-slot:icon>
            <i
              class="fa fa-file-contract text-info text-sm opacity-10"
              aria-hidden="true"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/commesse"
          :class="getRoute() === 'commesse' ? 'active' : ''"
          :navText="'Lista commesse'"
        >
          <template v-slot:icon>
            <i
              class="fa fa-file-contract text-info text-sm opacity-10"
              aria-hidden="true"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <div v-if="this.user?.roles === 'superadmin'">
        <li class="mt-3 nav-item">
          <h6
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6 ms-2"
          >
            GESTIONE BACKEND
          </h6>
        </li>
        <li class="nav-item">
          <sidenav-item
            url="/componenti"
            :class="getRoute() === 'componenti' ? 'active' : ''"
            :navText="'Componenti'"
          >
            <template v-slot:icon>
              <i
                class="fa fa-table text-warning text-sm opacity-10"
                aria-hidden="true"
              ></i>
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            url="/dp-list"
            :class="getRoute() === 'dp-list' ? 'active' : ''"
            :navText="'Dim. Tubo'"
          >
            <template v-slot:icon>
              <i
                class="fa fa-table text-warning text-sm opacity-10"
                aria-hidden="true"
              ></i>
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            url="/test-elettrici-list"
            :class="getRoute() === 'test-elettrici-list' ? 'active' : ''"
            :navText="'Test elettrici'"
          >
            <template v-slot:icon>
              <i
                class="fa fa-table text-warning text-sm opacity-10"
                aria-hidden="true"
              ></i>
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            url="/raccordi-list"
            :class="getRoute() === 'raccordi-list' ? 'active' : ''"
            navText="Raccordi"
          >
            <template v-slot:icon>
              <i
                class="fa fa-table text-warning text-sm opacity-10"
                aria-hidden="true"
              ></i>
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            url="/prova-idraulica-list"
            :class="getRoute() === 'prova-idraulica-list' ? 'active' : ''"
            navText="Prove idrauliche"
          >
            <template v-slot:icon>
              <i
                class="fa fa-table text-warning text-sm opacity-10"
                aria-hidden="true"
              ></i>
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            url="/giudizi-list"
            :class="getRoute() === 'giudizi' ? 'active' : ''"
            navText="Giudizi"
          >
            <template v-slot:icon>
              <i
                class="fa fa-table text-warning text-sm opacity-10"
                aria-hidden="true"
              ></i>
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            url="/misure-list"
            :class="getRoute() === 'misure' ? 'active' : ''"
            navText="Dim. Raccordo"
          >
            <template v-slot:icon>
              <i
                class="fa fa-table text-warning text-sm opacity-10"
                aria-hidden="true"
              ></i>
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            url="/tipi-list"
            :class="getRoute() === 'tipi' ? 'active' : ''"
            navText="Tipi"
          >
            <template v-slot:icon>
              <i
                class="fa fa-table text-warning text-sm opacity-10"
                aria-hidden="true"
              ></i>
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            url="/esami-visivi-list"
            :class="getRoute() === 'esami-visivi' ? 'active' : ''"
            navText="Esami visivi"
          >
            <template v-slot:icon>
              <i
                class="fa fa-table text-warning text-sm opacity-10"
                aria-hidden="true"
              ></i>
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            url="/user-list"
            :class="getRoute() === 'user-list' ? 'active' : ''"
            navText="Utenti"
          >
            <template v-slot:icon>
              <i
                class="fa fa-table text-warning text-sm opacity-10"
                aria-hidden="true"
              ></i>
            </template>
          </sidenav-item>
        </li>
      </div>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "FluorTecno",
      controls: "dashboardsExamples",
      isActive: "active",
      user: {},
    };
  },
  components: {
    SidenavItem,
    // SidenavCard
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
