<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="table-responsive p-0" v-if="tubes">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-secondary opacity-7"></th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  <span class="cursor-pointer" @click="this.showInput()"
                    >#</span
                  >
                  <input
                    v-if="this.showInputBox"
                    type="text"
                    v-model="this.searchNumber"
                    placeholder="Numero del tubo"
                    @input="this.filterData"
                    class="form-control form-control-sm w-50 d-inline ms-1"
                  />
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Numero di fabbrica
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Numero di matricola
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="tube in this.filteredTube" :key="tube._id">
                <table-tube-row
                  :item="tube"
                  :updateTableTubeRow="this.updateTableTubeRow"
                />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useTubeStore from "@/store/tube.store";
import TableTubeRow from "@/views/tube/tableRowTube.vue";

export default {
  name: "list-tubes",
  components: { TableTubeRow },
  props: {
    updateObjects: {
      type: Function,
    },
    tubes: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      tab: 1,
      listTubes: [],
      searchNumber: "",
      filteredTube: [],
      showInputBox: false,
    };
  },
  watch: {
    async tubes() {
      this.listTubes = await useTubeStore().getTubeList();
    },
    listTubes: {
      immediate: true,
      handler(newVal) {
        this.listTubes = newVal;
        this.filteredTube = newVal; // Imposta i dati iniziali
      },
    },
  },
  created() {
    this.listTubes = this.tubes;
  },
  mounted() {
    this.filterData();
  },
  methods: {
    updateTableTubeRow(tube, tabNumber) {
      this.tube_selected = tube;
      this.tab = tabNumber;
      this.updateObjects(this.tube_selected, this.tab, this.listTubes);
    },
    filterData() {
      if (this.searchNumber !== "") {
        this.filteredTube = this.listTubes.filter(
          (tube) => tube.tube_number === parseInt(this.searchNumber)
        );
      } else {
        this.filteredTube = this.listTubes;
      }
    },
    showInput() {
      this.showInputBox = !this.showInputBox;
      this.filteredTube = this.listTubes;
      this.searchNumber = "";
    },
  },
};
</script>
